import { api } from './api';

export const galleryService = {
  // Gallery Management
  getGalleriesForChatGroup: async (chatGroupId, page = 0, size = 10, sort = 'createdDate,desc') => {
    try {
      const response = await api.get(`/aluminiapp/v2/chat/galleries/group/${chatGroupId}`, {
        params: {
          page,
          size,
          sort
        }
      });
      console.log('Raw API response:', response);
      return response; // Return the full response object with data property
    } catch (error) {
      console.error('Error fetching galleries:', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  getGalleryById: async (galleryId) => {
    try {
      const response = await api.get(`/aluminiapp/v2/chat/galleries/${galleryId}`);
      console.log('Gallery details response:', response);
      return response;
    } catch (error) {
      console.error('Error fetching gallery:', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  createGallery: async (galleryData, coverImage) => {
    try {
      const formData = new FormData();
      
      // Add the JSON data
      formData.append('data', JSON.stringify({
        name: galleryData.name,
        description: galleryData.description,
        chatGroupId: galleryData.chatGroupId
      }));
      
      // Add cover image if exists
      if (coverImage) {
        formData.append('coverImage', coverImage);
      }
      
      const response = await api.post('/aluminiapp/v2/chat/galleries', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error creating gallery:', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  updateGallery: async (galleryId, galleryData, coverImage) => {
    try {
      const formData = new FormData();
      
      // Add the JSON data
      formData.append('data', JSON.stringify({
        name: galleryData.name,
        description: galleryData.description
      }));
      
      // Add cover image if exists
      if (coverImage) {
        formData.append('coverImage', coverImage);
      }
      
      const response = await api.put(`/aluminiapp/v2/chat/galleries/${galleryId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error updating gallery:', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  deleteGallery: async (galleryId) => {
    try {
      const response = await api.delete(`/aluminiapp/v2/chat/galleries/${galleryId}`);
      return response.data;
    } catch (error) {
      console.error('Error deleting gallery:', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  // Folder Management
  getFoldersForGallery: async (galleryId, page = 0, size = 10, sort = 'createdDate,desc') => {
    try {
      const response = await api.get(`/aluminiapp/v2/chat/galleries/${galleryId}/folders`, {
        params: {
          page,
          size,
          sort
        }
      });
      console.log('Folders response:', response);
      return response;
    } catch (error) {
      console.error('Error fetching folders:', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  getFolderById: async (folderId) => {
    try {
      const response = await api.get(`/aluminiapp/v2/chat/galleries/folders/${folderId}`);
      console.log('Folder details response:', response);
      return response;
    } catch (error) {
      console.error('Error fetching folder:', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  createFolder: async (folderData, coverImage) => {
    try {
      const formData = new FormData();
      
      // Add the JSON data
      formData.append('data', JSON.stringify({
        name: folderData.name,
        description: folderData.description,
        galleryId: folderData.galleryId
      }));
      
      // Add cover image if exists
      if (coverImage) {
        formData.append('coverImage', coverImage);
      }
      
      const response = await api.post('/aluminiapp/v2/chat/galleries/folders', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error creating folder:', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  updateFolder: async (folderId, folderData, coverImage) => {
    try {
      const formData = new FormData();
      
      // Add the JSON data
      formData.append('data', JSON.stringify({
        name: folderData.name,
        description: folderData.description
      }));
      
      // Add cover image if exists
      if (coverImage) {
        formData.append('coverImage', coverImage);
      }
      
      const response = await api.put(`/aluminiapp/v2/chat/galleries/folders/${folderId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error updating folder:', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  deleteFolder: async (folderId) => {
    try {
      const response = await api.delete(`/aluminiapp/v2/chat/galleries/folders/${folderId}`);
      return response.data;
    } catch (error) {
      console.error('Error deleting folder:', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  // Media Management
  getMediaInFolder: async (folderId, mediaType = null, page = 0, size = 10, sort = 'createdDate,desc') => {
    try {
      const params = {
        page,
        size,
        sort
      };

      if (mediaType) {
        params.mediaType = mediaType;
      }

      const response = await api.get(`/aluminiapp/v2/chat/galleries/folders/${folderId}/media`, {
        params
      });
      console.log('Media items response:', response);
      return response;
    } catch (error) {
      console.error('Error fetching media:', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  getMediaById: async (mediaId) => {
    try {
      const response = await api.get(`/aluminiapp/v2/chat/galleries/media/${mediaId}`);
      console.log('Media item details response:', response);
      return response;
    } catch (error) {
      console.error('Error fetching media item:', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  uploadMedia: async (mediaData, file, thumbnail = null) => {
    try {
      const formData = new FormData();
      
      // Add the JSON data
      formData.append('data', JSON.stringify({
        title: mediaData.title,
        description: mediaData.description,
        folderId: mediaData.folderId,
        mediaType: mediaData.mediaType // IMAGE, VIDEO, PDF
      }));
      
      // Add main file
      formData.append('file', file);

      // Add thumbnail if exists (for videos)
      if (thumbnail) {
        formData.append('thumbnail', thumbnail);
      }
      
      const response = await api.post('/aluminiapp/v2/chat/galleries/media', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error uploading media:', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  updateMediaDetails: async (mediaId, mediaData) => {
    try {
      const response = await api.put(`/aluminiapp/v2/chat/galleries/media/${mediaId}`, mediaData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error updating media details:', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  deleteMedia: async (mediaId) => {
    try {
      const response = await api.delete(`/aluminiapp/v2/chat/galleries/media/${mediaId}`);
      return response.data;
    } catch (error) {
      console.error('Error deleting media:', error.response ? error.response.data : error.message);
      throw error;
    }
  }
};

export default galleryService;