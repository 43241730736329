import axios from 'axios';

const api = axios.create({
  // baseURL: 'http://localhost:9091', // -- java backend
  baseURL: 'http://52.66.151.234', // Replace with your API base URL
});


api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => {
    console.log(`API Success - ${response.config.method.toUpperCase()} ${response.config.url}`, {
      status: response.status,
      data: response.data
    });
    return response;
  },
  (error) => {
    console.error(`API Error - ${error.config?.method?.toUpperCase()} ${error.config?.url}`, {
      status: error.response?.status,
      data: error.response?.data,
      message: error.message
    });
    
    if (error.response && error.response.status === 403) {
      console.log('403 error need to take further action');
      // Remove token from localStorage when a 403 error occurs
      localStorage.removeItem('token');

      // Optionally, you can navigate the user to the login page
      // if you're using React Router:
      // window.location.href = '/login';

      // Or if you have access to a navigate function:
      // navigate('/login');
    }
    return Promise.reject(error);
  }
);

export { api };