// EditPost.js - Updated validation logic
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getPostById, editPost } from "../services/postService";
import { useAuth } from "../contexts/AuthContext";
import ContentBlock from "../components/ContentBlocks/ContentBlock";
import CategorySelector from "../components/CategorySelector";

import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";

const EditPost = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [files, setFiles] = useState({});
  const [thumbnailFile, setThumbnailFile] = useState(null);
  const [existingThumbnailUrl, setExistingThumbnailUrl] = useState('');
  const [thumbnailPreview, setThumbnailPreview] = useState(null);
  const [postData, setPostData] = useState({
    userId: user.userId,
    authorId: user.userId,
    authorName: user.name,
    authorRole: user.role,
    schoolId: user.schoolId,
    title: "",
    summary: "",
    contentType: "ANNOUNCEMENT",
    ctaType: "",
    contentBlocks: [],
    categories: [],
    tags: [],
    targetAudience: `STATE#Karnataka`,
    visibilityLevel: "STATE",
    likeCount: 0,
    commentCount: 0,
    shareCount: 0,
  });

  const handleThumbnailChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      setThumbnailFile(file);
      
      // Create a preview URL for the selected file
      const previewUrl = URL.createObjectURL(file);
      setThumbnailPreview(previewUrl);
    } else {
      setError('Please select a valid image file for thumbnail');
    }
  };

  // Clean up the created object URL when component unmounts
  useEffect(() => {
    return () => {
      if (thumbnailPreview) {
        URL.revokeObjectURL(thumbnailPreview);
      }
    };
  }, [thumbnailPreview]);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const fetchedPost = await getPostById(id);
        console.log('Fetched post data:', fetchedPost);
        if (fetchedPost.thumbnail) {
          setExistingThumbnailUrl(fetchedPost.thumbnail);
        }
        setPostData((prev) => ({
          ...prev,
          ...fetchedPost,
          categories: fetchedPost.categories || [],
          tags: fetchedPost.tags || [],
          contentBlocks: fetchedPost.contentBlocks || [],
          contentType: fetchedPost.contentType || "ANNOUNCEMENT",
          ctaType: fetchedPost.ctaType || ""
        }));
      } catch (err) {
        setError("Failed to fetch post");
        console.error("Fetch post error:", err);
      }
    };

    fetchPost();
  }, [id, user.schoolId, user.userId, user.name, user.role]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPostData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCategoryChange = (newCategories) => {
    setPostData(prev => ({
      ...prev,
      categories: newCategories
    }));
  };


  const handleFileChange = (blockIdentifier, newFiles) => {
    console.log('=== File Change Start ===');
    console.log('Block identifier:', blockIdentifier);
    console.log('New files:', newFiles);
    
    if (!newFiles || !Array.isArray(newFiles)) {
      console.error('Invalid files received:', newFiles);
      return;
    }
    
    // Just use the blockIdentifier as string
    const blockKey = String(blockIdentifier);
    
    setFiles(prevFiles => {
      const existingBlockFiles = prevFiles[blockKey] || [];
      
      // Map through new files to ensure each has fileType
      const processedNewFiles = newFiles.map(file => {
        if (!file.fileType) {
          const newFile = new File([file], file.name, { type: file.type });
          newFile.fileType = file.type.startsWith('image/') ? 'image' : 
                            file.type.startsWith('video/') ? 'video' : 'file';
          return newFile;
        }
        return file;
      });
      
      return {
        ...prevFiles,
        [blockKey]: [...existingBlockFiles, ...processedNewFiles]
      };
    });
  };
  


  const updateContentBlock = (index, updatedBlock) => {
    setPostData((prev) => {
      const newBlocks = [...prev.contentBlocks];
      newBlocks[index] = updatedBlock;
      return { ...prev, contentBlocks: newBlocks };
    });
  };

  const removeContentBlock = (index) => {
    setPostData((prev) => ({
      ...prev,
      contentBlocks: prev.contentBlocks.filter((_, i) => i !== index),
    }));
  };

  const moveContentBlock = (fromIndex, toIndex) => {
    if (toIndex < 0 || toIndex >= postData.contentBlocks.length) return;
    setPostData((prev) => {
      const newBlocks = [...prev.contentBlocks];
      const [movedBlock] = newBlocks.splice(fromIndex, 1);
      newBlocks.splice(toIndex, 0, movedBlock);
      return { ...prev, contentBlocks: newBlocks };
    });
  };

  const addNewContentBlock = () => {
    setPostData((prev) => ({
      ...prev,
      contentBlocks: [
        ...prev.contentBlocks,
        {
          type: "Text",
          order: prev.contentBlocks.length + 1,
          textContent: { text: "" },
          createdDate: new Date().toISOString(),
          updatedDate: new Date().toISOString(),
        },
      ],
    }));
  };

  const validateContentBlocks = () => {
    const errors = [];
    
    if (!postData.contentBlocks || postData.contentBlocks.length === 0) {
      errors.push("At least one content block is required");
      return errors;
    }

    postData.contentBlocks.forEach((block, index) => {
      // Check for uploaded files in each block type
      const blockFiles = files[block.order.toString()] || [];
      
      switch (block.type) {
        case 'Image':
          const hasImageFile = blockFiles.some(file => file.fileType === 'image');
          if (!hasImageFile && !block.imageContent?.url?.trim()) {
            errors.push(`Content Block ${index + 1}: Please either upload an image or provide an image URL`);
          }
          break;
          case 'Video':
            const hasVideoFile = blockFiles.some(file => file.fileType === 'video');
            const hasVideoUrl = block.videoContent?.url?.trim();
            const hasThumbnailFile = blockFiles.some(file => file.fileType === 'thumbnail');
            const hasThumbnailUrl = block.videoContent?.thumbnailUrl?.trim();
          
            // Check video content
            if (!hasVideoFile && !hasVideoUrl) {
              errors.push(`Content Block ${block.blockOrder}: Please upload a video file`);
            }
          
            // Check thumbnail
            if (!hasThumbnailFile && !hasThumbnailUrl) {
              errors.push(`Content Block ${block.blockOrder}: Please upload a thumbnail`);
            }
            break;
      }
    });

    return errors;
  };

  const removeFieldsNotRequiredForPostCreation = (submissionData) => {
    const newData = { ...submissionData };
    delete newData.postId;
    delete newData.profilePictureUrl;
    return newData;
  };

  const hasValidationErrors = postData.contentBlocks.some(block => block.hasValidationError);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setIsSubmitting(true);

    console.log('=== Form Submission Start ===');
console.log('PostData:', postData);
console.log('Files state:', files);
console.log('Content blocks:', postData.contentBlocks);

    try {
      let submissionData = { ...postData };

      // Basic validation
      if (!submissionData.title.trim()) {
        throw new Error("Title is required");
      }

      if (!submissionData.summary.trim()) {
        throw new Error("Summary is required");
      }

      if (!submissionData.contentType) {
        throw new Error("Content Type is required");
      }

      // Validate content blocks for empty content
      const blockErrors = validateContentBlocks();
      if (blockErrors.length > 0) {
        throw new Error(blockErrors.join('\n'));
      }

      // Check for validation errors in blocks
      if (hasValidationErrors) {
        throw new Error("Please fix validation errors in content blocks before submitting");
      }

      // Clean up data for submission
      submissionData.categories = submissionData.categories || [];
      submissionData.tags = submissionData.tags || [];
      submissionData.pk = submissionData.postId;
      submissionData = removeFieldsNotRequiredForPostCreation(submissionData);

      const formData = new FormData();
      if (thumbnailFile) {
        formData.append('thumbnail', thumbnailFile);
      }
      formData.append("metadata", JSON.stringify(submissionData));

      // Handle file uploads with improved error handling
      Object.entries(files).forEach(([blockIdentifier, blockFiles]) => {
        if (!Array.isArray(blockFiles)) {
          console.error(`Invalid files array for block ${blockIdentifier}:`, blockFiles);
          return;
        }

        const contentBlock = postData.contentBlocks.find(block => 
          block.order.toString() === blockIdentifier.toString()
        );

        if (!contentBlock) {
          console.error(`No matching content block found for identifier ${blockIdentifier}`);
          return;
        }
      
        blockFiles.forEach((file, index) => {
          if (!(file instanceof File)) {
            console.error(`Invalid file object in block ${blockIdentifier}:`, file);
            return;
          }
      
          // Create a unique filename that includes block order and file type
          const fileType = file.fileType || 'unknown';
          const fileName = `file_${contentBlock.order}_${fileType}_${index}${getFileExtension(file.name)}`;

          formData.append('file', file, fileName);
        });
      });

      // Log the final FormData contents for debugging
      console.log('=== FormData Contents ===');
      for (let [key, value] of formData.entries()) {
        if (value instanceof File) {
          console.log('File entry:', {
            key,
            fileName: value.name,
            fileType: value.type,
            fileSize: value.size,
            customFileType: value.fileType // our custom property
          });
        } else {
          console.log('Non-file entry:', { key, value });
        }
      }


      // Debug: Additional logging for thumbnail file
      if (thumbnailFile) {
        console.log('Thumbnail details:', {
          name: thumbnailFile.name,
          type: thumbnailFile.type,
          size: thumbnailFile.size,
          lastModified: new Date(thumbnailFile.lastModified).toISOString()
        });
      } else {
        console.log('No thumbnail file selected');
      }

      await editPost(id, formData);
      navigate("/");
    } catch (err) {
      setError(err.message || "Failed to update post. Please try again.");
      console.error("Update post error:", err);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Helper function to get file extension
function getFileExtension(filename) {
  return filename.substring(filename.lastIndexOf('.'));
}

  return (
    <Container maxWidth="md">
      <Typography variant="h4" align="center" sx={{ mt: 4, mb: 2 }}>
        Edit Post
      </Typography>
      {error && (
        <Typography color="error" align="center" sx={{ mb: 2, whiteSpace: 'pre-line' }}>
          {error}
        </Typography>
      )}
      <form onSubmit={handleSubmit} noValidate>
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle1" sx={{ mb: 1 }}>Post Thumbnail</Typography>
          {thumbnailPreview ? (
            <Box sx={{ mb: 1 }}>
              <img 
                src={thumbnailPreview} 
                alt="New thumbnail preview" 
                style={{ maxWidth: '200px', marginBottom: '8px', borderRadius: '4px' }} 
              />
            </Box>
          ) : existingThumbnailUrl && (
            <Box sx={{ mb: 1 }}>
              <img 
                src={existingThumbnailUrl} 
                alt="Current thumbnail" 
                style={{ maxWidth: '200px', marginBottom: '8px', borderRadius: '4px' }} 
              />
            </Box>
          )}
          <input
            accept="image/*"
            type="file"
            onChange={handleThumbnailChange}
            style={{ display: 'none' }}
            id="thumbnail-upload"
          />
          <label htmlFor="thumbnail-upload">
            <Button
              variant="outlined"
              component="span"
              fullWidth
              sx={{ mb: 2 }}
            >
              {thumbnailFile ? thumbnailFile.name : 'Update Thumbnail Image'}
            </Button>
          </label>
        </Box>
        <Box sx={{ mb: 2 }}>
          <TextField
            label="Title"
            name="title"
            value={postData.title}
            onChange={handleInputChange}
            required
            fullWidth
            error={!postData.title.trim()}
          />
        </Box>
        <Box sx={{ mb: 2 }}>
          <TextField
            label="Summary"
            name="summary"
            value={postData.summary}
            onChange={handleInputChange}
            required
            multiline
            rows={4}
            fullWidth
            error={!postData.summary.trim()}
          />
        </Box>
        <Box sx={{ mb: 2 }}>
          <FormControl fullWidth required>
            <InputLabel id="content-type-label">Content Type</InputLabel>
            <Select
              labelId="content-type-label"
              id="content-type"
              name="contentType"
              value={postData.contentType}
              label="Content Type"
              onChange={handleInputChange}
            >
              <MenuItem value="ANNOUNCEMENT">Announcement</MenuItem>
              <MenuItem value="CTA">Call to Action</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {postData.contentType === "CTA" && (
          <Box sx={{ mb: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="cta-type-label">CTA Type</InputLabel>
              <Select
                labelId="cta-type-label"
                id="cta-type"
                name="ctaType"
                value={postData.ctaType || ""}
                label="CTA Type"
                onChange={handleInputChange}
              >
                <MenuItem value="join_now">Join Now</MenuItem>
                <MenuItem value="attend_event">Attend Event</MenuItem>
                <MenuItem value="interested">Interested</MenuItem>
                <MenuItem value="donate_now">Donate Now</MenuItem>
              </Select>
            </FormControl>
          </Box>
        )}

        <CategorySelector 
          categories={postData.categories} 
          onCategoryChange={handleCategoryChange}
        />

        <Box sx={{ mb: 2 }}>
          <TextField
            label="Tags (comma-separated)"
            name="tags"
            value={postData.tags.length > 0 ? postData.tags.join(", ") : ""}
            onChange={(e) => {
              const value = e.target.value;
              setPostData((prev) => ({
                ...prev,
                tags: value.split(",").map((item) => item.trim())
              }));
            }}
            fullWidth
          />
        </Box>

        <Box sx={{ mb: 2 }}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            Content Blocks
          </Typography>
          {postData.contentBlocks.map((block, index) => (
            <ContentBlock
              key={index}
              block={block}
              index={index}
              updateBlock={updateContentBlock}
              removeBlock={removeContentBlock}
              moveBlock={moveContentBlock}
              onFileChange={handleFileChange}
            />
          ))}
          <Button
            variant="outlined"
            onClick={addNewContentBlock}
            sx={{ mt: 2 }}
          >
            Add New Content Block
          </Button>
        </Box>
        <Box sx={{ position: 'relative' }}>
          <Button 
            type="submit" 
            variant="contained" 
            color="primary" 
            fullWidth
            disabled={
              isSubmitting || 
              !postData.title.trim() || 
              !postData.summary.trim() || 
              !postData.contentType ||
              postData.contentBlocks.length === 0 ||
              hasValidationErrors
            }
          >
            {isSubmitting ? 'Updating Post...' : 'Update Post'}
          </Button>
          {isSubmitting && (
            <CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Box>
      </form>
    </Container>
  );
};

export default EditPost;