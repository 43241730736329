import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Typography,
  CircularProgress,
  Alert
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ImageIcon from '@mui/icons-material/Image';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { galleryService } from '../../services/galleryService';

const UploadMediaDialog = ({ open, onClose, folderId, onUploadComplete }) => {
  const [mediaData, setMediaData] = useState({
    title: '',
    description: '',
    folderId: folderId,
    mediaType: 'IMAGE'
  });
  const [file, setFile] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [thumbnailPreview, setThumbnailPreview] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    // Set the folder ID when it changes
    setMediaData(prev => ({ ...prev, folderId }));
  }, [folderId]);

  // Clean up the preview URLs when component unmounts or when file/thumbnail changes
  useEffect(() => {
    return () => {
      if (filePreview) {
        URL.revokeObjectURL(filePreview);
      }
      if (thumbnailPreview) {
        URL.revokeObjectURL(thumbnailPreview);
      }
    };
  }, [filePreview, thumbnailPreview]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMediaData(prev => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) return;

    // Validate file based on media type
    if (mediaData.mediaType === 'IMAGE' && !selectedFile.type.startsWith('image/')) {
      setError('Please select a valid image file');
      return;
    }
    
    if (mediaData.mediaType === 'VIDEO' && !selectedFile.type.startsWith('video/')) {
      setError('Please select a valid video file');
      return;
    }
    
    if (mediaData.mediaType === 'PDF' && selectedFile.type !== 'application/pdf') {
      setError('Please select a valid PDF file');
      return;
    }

    setFile(selectedFile);
    
    // Create a preview if it's an image or video
    if (mediaData.mediaType === 'IMAGE' || mediaData.mediaType === 'VIDEO') {
      const previewUrl = URL.createObjectURL(selectedFile);
      setFilePreview(previewUrl);
    } else {
      setFilePreview(null);
    }
    
    setError('');
  };

  const handleThumbnailChange = (e) => {
    const selectedThumbnail = e.target.files[0];
    if (!selectedThumbnail) return;

    // Validate thumbnail (should be an image)
    if (!selectedThumbnail.type.startsWith('image/')) {
      setError('Please select a valid image file for the thumbnail');
      return;
    }

    setThumbnail(selectedThumbnail);
    const previewUrl = URL.createObjectURL(selectedThumbnail);
    setThumbnailPreview(previewUrl);
    setError('');
  };

  const resetForm = () => {
    setMediaData({
      title: '',
      description: '',
      folderId: folderId,
      mediaType: 'IMAGE'
    });
    setFile(null);
    setThumbnail(null);
    setFilePreview(null);
    setThumbnailPreview(null);
    setError('');
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const handleSubmit = async () => {
    // Validate form
    if (!file) {
      setError('Please select a file to upload');
      return;
    }

    if (mediaData.mediaType === 'VIDEO' && !thumbnail) {
      setError('Please upload a thumbnail for the video');
      return;
    }

    setIsSubmitting(true);
    setError('');

    try {
      const response = await galleryService.uploadMedia(mediaData, file, thumbnail);
      console.log('Upload media response:', response);
      handleClose();
      if (onUploadComplete) {
        onUploadComplete();
      }
    } catch (error) {
      console.error('Failed to upload media:', error);
      setError('Failed to upload media. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Render file icon based on media type
  const renderFileIcon = () => {
    switch (mediaData.mediaType) {
      case 'IMAGE':
        return <ImageIcon sx={{ fontSize: 60, color: '#5E35B1' }} />;
      case 'VIDEO':
        return <VideoFileIcon sx={{ fontSize: 60, color: '#5E35B1' }} />;
      case 'PDF':
        return <PictureAsPdfIcon sx={{ fontSize: 60, color: '#5E35B1' }} />;
      default:
        return <CloudUploadIcon sx={{ fontSize: 60, color: '#5E35B1' }} />;
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Upload Media</DialogTitle>
      <DialogContent>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <FormControl fullWidth margin="normal">
          <InputLabel id="media-type-label">Media Type</InputLabel>
          <Select
            labelId="media-type-label"
            id="mediaType"
            name="mediaType"
            value={mediaData.mediaType}
            label="Media Type"
            onChange={handleInputChange}
          >
            <MenuItem value="IMAGE">Image</MenuItem>
            <MenuItem value="VIDEO">Video</MenuItem>
            <MenuItem value="PDF">PDF Document</MenuItem>
          </Select>
        </FormControl>

        <TextField
          label="Title"
          name="title"
          value={mediaData.title}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />

        <TextField
          label="Description"
          name="description"
          value={mediaData.description}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          multiline
          rows={3}
        />

        {/* File Upload Section */}
        <Box sx={{ mt: 2, mb: 2 }}>
          <Typography variant="subtitle1" gutterBottom>
            Upload {mediaData.mediaType === 'IMAGE' ? 'Image' : mediaData.mediaType === 'VIDEO' ? 'Video' : 'PDF'}
          </Typography>
          
          {filePreview && mediaData.mediaType === 'IMAGE' ? (
            <Box sx={{ mb: 2, textAlign: 'center' }}>
              <img
                src={filePreview}
                alt="Preview"
                style={{ maxWidth: '100%', maxHeight: '200px', objectFit: 'contain' }}
              />
            </Box>
          ) : filePreview && mediaData.mediaType === 'VIDEO' ? (
            <Box sx={{ mb: 2, textAlign: 'center' }}>
              <video
                src={filePreview}
                controls
                style={{ maxWidth: '100%', maxHeight: '200px' }}
              />
            </Box>
          ) : file ? (
            <Box sx={{ mb: 2, p: 2, border: '1px dashed #ccc', textAlign: 'center' }}>
              {renderFileIcon()}
              <Typography variant="body2">{file.name}</Typography>
            </Box>
          ) : (
            <Box
              sx={{
                p: 3,
                border: '2px dashed #ccc',
                borderRadius: 1,
                textAlign: 'center',
                cursor: 'pointer',
                '&:hover': { borderColor: '#5E35B1' }
              }}
              onClick={() => document.getElementById('file-input').click()}
            >
              {renderFileIcon()}
              <Typography variant="body1" sx={{ mt: 1 }}>
                Click to select a {mediaData.mediaType.toLowerCase()} file
              </Typography>
            </Box>
          )}
          
          <input
            type="file"
            id="file-input"
            onChange={handleFileChange}
            style={{ display: 'none' }}
            accept={
              mediaData.mediaType === 'IMAGE' 
                ? 'image/*' 
                : mediaData.mediaType === 'VIDEO'
                  ? 'video/*'
                  : 'application/pdf'
            }
          />
          
          <Button
            variant="outlined"
            onClick={() => document.getElementById('file-input').click()}
            startIcon={<CloudUploadIcon />}
            fullWidth
            sx={{ mt: 1 }}
          >
            Select File
          </Button>
        </Box>

        {/* Thumbnail Upload Section (only for videos) */}
        {mediaData.mediaType === 'VIDEO' && (
          <Box sx={{ mt: 3, mb: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              Upload Thumbnail (required for videos)
            </Typography>
            
            {thumbnailPreview ? (
              <Box sx={{ mb: 2, textAlign: 'center' }}>
                <img
                  src={thumbnailPreview}
                  alt="Thumbnail Preview"
                  style={{ maxWidth: '100%', maxHeight: '200px', objectFit: 'contain' }}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  p: 3,
                  border: '2px dashed #ccc',
                  borderRadius: 1,
                  textAlign: 'center',
                  cursor: 'pointer',
                  '&:hover': { borderColor: '#5E35B1' }
                }}
                onClick={() => document.getElementById('thumbnail-input').click()}
              >
                <ImageIcon sx={{ fontSize: 60, color: '#5E35B1' }} />
                <Typography variant="body1" sx={{ mt: 1 }}>
                  Click to select a thumbnail image
                </Typography>
              </Box>
            )}
            
            <input
              type="file"
              id="thumbnail-input"
              onChange={handleThumbnailChange}
              style={{ display: 'none' }}
              accept="image/*"
            />
            
            <Button
              variant="outlined"
              onClick={() => document.getElementById('thumbnail-input').click()}
              startIcon={<ImageIcon />}
              fullWidth
              sx={{ mt: 1 }}
            >
              Select Thumbnail
            </Button>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={isSubmitting || !file || (mediaData.mediaType === 'VIDEO' && !thumbnail)}
          startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
        >
          {isSubmitting ? 'Uploading...' : 'Upload'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadMediaDialog;