import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Box,
  Grid,
  Button,
  Pagination,
  CircularProgress,
  Breadcrumbs,
  Link,
  Divider,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tabs,
  Tab,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ImageIcon from '@mui/icons-material/Image';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { galleryService } from '../../services/galleryService';
import MediaItem from '../../components/chat/MediaItem';
import UploadMediaDialog from '../../components/chat/UploadMediaDialog';

const FolderDetails = () => {
  const { folderId } = useParams();
  const navigate = useNavigate();
  
  const [folder, setFolder] = useState(null);
  const [gallery, setGallery] = useState(null);
  const [mediaItems, setMediaItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [mediaToDelete, setMediaToDelete] = useState(null);
  const [refreshKey, setRefreshKey] = useState(0);
  const [mediaType, setMediaType] = useState('ALL');
  
  const pageSize = 12;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError('');
      try {
        // Fetch folder details
        const folderResponse = await galleryService.getFolderById(folderId);
        if (!folderResponse || !folderResponse.data) {
          console.error('Unexpected folder response format:', folderResponse);
          setError('Unexpected response format when loading folder details. Please try again.');
          setLoading(false);
          return;
        }
        setFolder(folderResponse.data);
        
        // Fetch gallery details
        const galleryResponse = await galleryService.getGalleryById(folderResponse.data.galleryId);
        if (!galleryResponse || !galleryResponse.data) {
          console.error('Unexpected gallery response format:', galleryResponse);
          setError('Unexpected response format when loading gallery details. Please try again.');
          setLoading(false);
          return;
        }
        setGallery(galleryResponse.data);
        
        // Fetch media items for this folder
        const type = mediaType === 'ALL' ? null : mediaType;
        const mediaResponse = await galleryService.getMediaInFolder(
          folderId, 
          type,
          page - 1, 
          pageSize
        );
        
        console.log('Media response:', mediaResponse);
        if (!mediaResponse || !mediaResponse.data) {
          console.error('Unexpected media response format:', mediaResponse);
          setError('Unexpected response format when loading media items. Please try again.');
          setLoading(false);
          return;
        }
        setMediaItems(mediaResponse.data.content || []);
        setTotalPages(mediaResponse.data.totalPages || 1);
      } catch (err) {
        console.error('Error fetching data:', err);
        if (err.response) {
          console.error('Error response:', err.response.data);
          setError(`Failed to load folder data: ${err.response.data.message || 'Server error'}`);
        } else if (err.request) {
          setError('Network error. Please check your connection.');
        } else {
          setError('Failed to load folder data. Please try again.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [folderId, page, refreshKey, mediaType]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleMediaTypeChange = (event) => {
    setMediaType(event.target.value);
    setPage(1); // Reset to first page when changing filter
  };

  const handleUploadMedia = () => {
    setUploadDialogOpen(true);
  };

  const handleDeletePrompt = (media) => {
    setMediaToDelete(media);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!mediaToDelete) return;
    
    try {
      await galleryService.deleteMedia(mediaToDelete.id);
      setDeleteDialogOpen(false);
      setMediaToDelete(null);
      // Refresh the media list
      setRefreshKey(prev => prev + 1);
    } catch (err) {
      console.error('Error deleting media:', err);
      setError('Failed to delete media. Please try again.');
    }
  };

  const handleMediaDetailsUpdated = (updatedMedia) => {
    // Update the media item in the local state
    setMediaItems(prevItems => 
      prevItems.map(item => 
        item.id === updatedMedia.id ? {...item, ...updatedMedia} : item
      )
    );
  };

  if (loading && !folder) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      {/* Breadcrumb Navigation */}
      <Box mb={3}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
          <Link 
            color="inherit" 
            component="button"
            onClick={() => navigate('/chat-admin')}
            sx={{ textDecoration: 'none' }}
          >
            Chat Groups
          </Link>
          <Link 
            color="inherit" 
            component="button"
            onClick={() => navigate(`/chat-gallery/groups/${gallery?.chatGroupId}`)}
            sx={{ textDecoration: 'none' }}
          >
            Galleries
          </Link>
          <Link 
            color="inherit" 
            component="button"
            onClick={() => navigate(`/chat-gallery/${gallery?.id}`)}
            sx={{ textDecoration: 'none' }}
          >
            {gallery?.name}
          </Link>
          <Typography color="text.primary">
            {folder?.name || 'Folder Details'}
          </Typography>
        </Breadcrumbs>
      </Box>
      
      {/* Header Section */}
      <Box 
        display="flex" 
        justifyContent="space-between" 
        alignItems="center"
        mb={3}
      >
        <Box>
          <Typography variant="h4" gutterBottom>
            {folder?.name || 'Folder Details'}
          </Typography>
          {folder?.description && (
            <Typography variant="body1" color="text.secondary">
              {folder.description}
            </Typography>
          )}
        </Box>
        <Button
          variant="contained"
          color="primary"
          startIcon={<UploadFileIcon />}
          onClick={handleUploadMedia}
        >
          Upload Media
        </Button>
      </Box>
      
      <Divider sx={{ mb: 3 }} />
      
      {/* Error Message */}
      {error && (
        <Alert 
          severity="error" 
          sx={{ mb: 3 }}
          action={
            <Button 
              color="inherit" 
              size="small" 
              startIcon={<RefreshIcon />}
              onClick={() => setRefreshKey(prev => prev + 1)}
            >
              Retry
            </Button>
          }
        >
          {error}
        </Alert>
      )}
      
      {/* Media Type Filter */}
      <Box 
        sx={{ 
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 3
        }}
      >
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel id="media-type-filter-label">Filter by Type</InputLabel>
          <Select
            labelId="media-type-filter-label"
            id="media-type-filter"
            value={mediaType}
            label="Filter by Type"
            onChange={handleMediaTypeChange}
          >
            <MenuItem value="ALL">All Media Types</MenuItem>
            <MenuItem value="IMAGE">Images</MenuItem>
            <MenuItem value="VIDEO">Videos</MenuItem>
            <MenuItem value="PDF">Documents</MenuItem>
          </Select>
        </FormControl>
        
        <Typography variant="body2" color="text.secondary">
          {mediaItems.length > 0 
            ? `Showing ${mediaItems.length} of ${totalPages * pageSize} items`
            : 'No media items found'
          }
        </Typography>
      </Box>
      
      {/* Media Items Grid */}
      {mediaItems.length > 0 ? (
        <Grid container spacing={3}>
          {mediaItems.map((media) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={media.id}>
              <MediaItem 
                media={media} 
                onDelete={handleDeletePrompt}
                onDetailsUpdated={handleMediaDetailsUpdated}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box 
          sx={{ 
            minHeight: '40vh', 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            justifyContent: 'center',
            backgroundColor: 'rgba(94, 53, 177, 0.05)',
            borderRadius: 2,
            p: 3
          }}
        >
          <UploadFileIcon sx={{ fontSize: 80, color: '#5E35B1', opacity: 0.5, mb: 2 }} />
          <Typography variant="h6" color="text.secondary" gutterBottom>
            No media files found
          </Typography>
          <Typography variant="body2" color="text.secondary" align="center" mb={3}>
            Upload your first media file to this folder by clicking the "Upload Media" button.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<UploadFileIcon />}
            onClick={handleUploadMedia}
          >
            Upload Media
          </Button>
        </Box>
      )}
      
      {/* Pagination */}
      {totalPages > 1 && (
        <Box display="flex" justifyContent="center" mt={4}>
          <Pagination 
            count={totalPages} 
            page={page}
            onChange={handlePageChange}
            color="primary"
          />
        </Box>
      )}
      
      {/* Upload Media Dialog */}
      <UploadMediaDialog
        open={uploadDialogOpen}
        onClose={() => setUploadDialogOpen(false)}
        folderId={folderId}
        onUploadComplete={() => setRefreshKey(prev => prev + 1)}
      />
      
      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this {mediaToDelete?.mediaType?.toLowerCase()} file?
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default FolderDetails;