import React from 'react';
import {
  TableRow,
  TableCell,
  Avatar,
  Typography,
  Box,
  Chip,
  IconButton,
} from '@mui/material';
import { Person, MoreVert } from '@mui/icons-material';

const UserTableRow = ({ user, onActionClick }) => {
  return (
    <TableRow>
      <TableCell>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar 
            src={user.profilePictureUrl} 
            alt={user.displayName}
            sx={{ width: 40, height: 40, mr: 2 }}
          >
            {!user.profilePictureUrl && <Person />}
          </Avatar>
          <Box>
            <Typography variant="subtitle2">
              {user.displayName || 'No Name'}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              ID: {user.id}
            </Typography>
          </Box>
        </Box>
      </TableCell>
      
      <TableCell>
        <Typography variant="body2">
          📱 {user.phoneNumber || 'N/A'}
        </Typography>
        <Typography variant="body2">
          📧 {user.email || 'N/A'}
        </Typography>
      </TableCell>

      <TableCell>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {user.roles?.map((role) => (
            <Chip
              key={role}
              label={role}
              size="small"
              color="primary"
              variant="outlined"
            />
          )) || <Chip label="USER" size="small" color="primary" variant="outlined" />}
        </Box>
        <Typography variant="caption" color="textSecondary" display="block" sx={{ mt: 1 }}>
          Created: {new Date(user.createdAt).toLocaleDateString()}
        </Typography>
      </TableCell>

      <TableCell align="right">
        <IconButton
          size="small"
          onClick={(event) => onActionClick(event, user)}
        >
          <MoreVert />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default UserTableRow;