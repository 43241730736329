import React, { useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Avatar,
  Collapse,
  Chip,
  Button,
  CircularProgress,
  Snackbar,
  Alert
} from '@mui/material';
import { CloudDownload as DownloadIcon } from '@mui/icons-material';
import { exportEnrolledUsers } from '../../services/initiativeService';

const EnrolledUsersTable = ({ open, users = [], formatDate, initiativeId }) => {
  const [exporting, setExporting] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  return (
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 2 }}>
            <Typography variant="h6" gutterBottom component="div">
              Enrolled Users ({users?.length || 0})
            </Typography>
            {users.length > 0 && (
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  startIcon={exporting ? <CircularProgress size={16} /> : <DownloadIcon />}
                  disabled={exporting || users.length === 0}
                  onClick={async () => {
                    try {
                      setExporting(true);
                      await exportEnrolledUsers(initiativeId);
                      setSnackbar({
                        open: true,
                        message: 'Enrolled users data exported successfully!',
                        severity: 'success'
                      });
                    } catch (error) {
                      console.error('Export enrolled users error:', error);
                      setSnackbar({
                        open: true,
                        message: 'Failed to export enrolled users. Please try again.',
                        severity: 'error'
                      });
                    } finally {
                      setExporting(false);
                    }
                  }}
                >
                  {exporting ? 'Exporting...' : 'Export Users'}
                </Button>
              </Box>
            )}
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Profile</TableCell>
                  <TableCell>Contact Info</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Joined</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(users) && users.map((user) => {
                  console.log('Rendering user:', user);
                  return (
                    <TableRow key={user.id}>
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                          <Avatar
                            src={user.profilePictureUrl || ''}
                            alt={user.displayName || user.username}
                            sx={{ width: 40, height: 40 }}
                          />
                          <Typography variant="body2">
                            {user.displayName || user.username || 'N/A'}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {user.phoneNumber || 'N/A'}
                        </Typography>
                        {user.email && (
                          <Typography variant="body2" color="textSecondary">
                            {user.email}
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell>
                        {user.role && (
                          <Chip 
                            label={user.role} 
                            size="small"
                            color={user.role === 'Admin' ? 'primary' : 'default'}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        {formatDate(user.createdAt)}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {(!users || users.length === 0) && (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      No users enrolled yet
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={() => setSnackbar({...snackbar, open: false})}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setSnackbar({...snackbar, open: false})} 
          severity={snackbar.severity} 
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </TableRow>
  );
};

export default EnrolledUsersTable;