import {api} from "./api";

export const listUsers = async (page = 0, size = 10) => {
    try {
      const response = await api.get(`/aluminiapp/v2/profile?page=${page}&size=${size}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  
  export const createUser = async (userData) => {
    // Placeholder for create user service
    console.log('Creating user:', userData);
    // TODO: Implement actual API call
  };
  
  export const getUser = async (userId) => {
    try {
      const response = await api.get(`/aluminiapp/v2/profile/${userId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  
  export const updateUser = async (userId, userData, profilePicture) => {
    try {
      const formData = new FormData();
      
      // Create registration format exactly matching API expectations
      const registrationFormat = {
        personalInfo: {
          fullName: userData.name || '',  // Changed from displayName to name
          contactNumber: userData.phoneNumber || '',
          email: userData.email || '',
          dateOfBirth: userData.dateOfBirth || '',
          gender: userData.gender || '',
          whatsappNumber: userData.whatsappNumber || '',
          countryOfResidence: userData.countryOfResidence || '',
          sector: userData.sector || '',
          // Additional required fields from API
          displayName: userData.displayName || '',
          role: userData.role || '',
          bio: userData.bio || '',
          currentPosition: userData.currentPosition || '',
          linkedInProfile: userData.linkedInProfile || '',
          location: userData.location || '',
          currentLocation: userData.currentLocation || ''
        },
        schoolInfoList: (userData.schoolInfoList || []).map(school => ({
          udiseCode: school.udiseCode || '',
          name: school.name || '',
          blockName: school.blockName || '',
          district: school.district || '',
          classOfJoining: school.classOfJoining || '',
          classOfPassing: school.classOfPassing || '',
          favoriteTeacher: school.favoriteTeacher || '',
          teacherContactDetails: school.teacherContactDetails || '',
          isConnectedToSchool: school.isConnectedToSchool || false,
          willingToConnectWithSchool: school.willingToConnectWithSchool || false
        }))
      };
  
      formData.append('data', JSON.stringify(registrationFormat));
      
      if (profilePicture instanceof File) {
        formData.append('profilePicture', profilePicture);
      }
  
      const response = await api.put(`/aluminiapp/v2/profile/${userId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      });
      return response.data;
    } catch (error) {
      console.error('Update user error:', error.response?.data || error);
      throw error;
    }
  };
  
  export const deleteUser = async (userId) => {
    try {
      await api.delete(`/aluminiapp/v2/profile/${userId}`);
    } catch (error) {
      throw error;
    }
  };

  // Role Management Functions
  export const assignRole = async (phoneNumber, role) => {
    try {
      const response = await api.post('/aluminiapp/v2/user-manager/roles/assign', null, {
        params: { phoneNumber, role }
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  export const removeRole = async (phoneNumber, role) => {
    try {
      const response = await api.post('/aluminiapp/v2/user-manager/roles/remove', null, {
        params: { phoneNumber, role }
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  export const checkRole = async (phoneNumber, role) => {
    try {
      const response = await api.get('/aluminiapp/v2/user-manager/roles/check', {
        params: { phoneNumber, role }
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  export const assignChatAdmin = async (phoneNumber) => {
    try {
      const response = await api.post('/aluminiapp/v2/user-manager/roles/assign-chat-admin', null, {
        params: { phoneNumber }
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };