// Previous imports remain the same
import { PeopleOutline as PeopleIcon, CloudDownload as DownloadIcon } from '@mui/icons-material';
import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Pagination,
  Snackbar,
  Alert,
  CircularProgress
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getEventsByMonth, exportEventMembers } from '../services/schoolEventService';

const SchoolEventsList = () => {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [exportingId, setExportingId] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const PAGE_SIZE = 12;

  const fetchEvents = async () => {
    try {
      const data = await getEventsByMonth(selectedYear, selectedMonth, page - 1, PAGE_SIZE);
      setEvents(data.content || []);
      setTotalPages(data.totalPages || 0);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching school events:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, [selectedYear, selectedMonth, page]);

  const months = [
    { value: 1, label: 'January' },
    { value: 2, label: 'February' },
    { value: 3, label: 'March' },
    { value: 4, label: 'April' },
    { value: 5, label: 'May' },
    { value: 6, label: 'June' },
    { value: 7, label: 'July' },
    { value: 8, label: 'August' },
    { value: 9, label: 'September' },
    { value: 10, label: 'October' },
    { value: 11, label: 'November' },
    { value: 12, label: 'December' }
  ];

  const years = Array.from({ length: 5 }, (_, i) => new Date().getFullYear() - i);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
    setPage(1);
  };

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
    setPage(1);
  };

  if (loading && !events.length) {
    return <Box sx={{ p: 3 }}>Loading events...</Box>;
  }

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h4">School Events</Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <FormControl sx={{ width: 120 }}>
            <InputLabel>Year</InputLabel>
            <Select
              value={selectedYear}
              label="Year"
              onChange={handleYearChange}
            >
              {years.map(year => (
                <MenuItem key={year} value={year}>{year}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ width: 120 }}>
            <InputLabel>Month</InputLabel>
            <Select
              value={selectedMonth}
              label="Month"
              onChange={handleMonthChange}
            >
              {months.map(month => (
                <MenuItem key={month.value} value={month.value}>
                  {month.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button 
            variant="contained" 
            onClick={() => navigate('/create-school-event')}
          >
            Add Event
          </Button>
        </Box>
      </Box>

      <Grid container spacing={2}>
        {events.map((event) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={event.id}>
            <Paper
            elevation={2}
            sx={{
            p: 2,
            transition: 'transform 0.2s, box-shadow 0.2s',
            '&:hover': {
              transform: 'translateY(-4px)',
            boxShadow: 4
            },
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
            }}
            >
              <Typography 
              variant="h6" 
                noWrap 
                  sx={{ mb: 2, cursor: 'pointer' }}
                  onClick={() => navigate(`/edit-school-event/${event.id}`)}
                >
                  {event.eventName}
                </Typography>
              <Box sx={{ mt: 'auto' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                  <Typography variant="body2" color="text.secondary">
                    {new Date(event.eventDate).toLocaleDateString()}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {event.eventTime}
                  </Typography>
                </Box>
                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}>
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center',
                    gap: 1,
                    color: 'primary.main'
                  }}>
                    <PeopleIcon fontSize="small" />
                    <Typography variant="body2">
                      {event.interestedCount || 0} interested
                    </Typography>
                  </Box>
                  {event.interestedCount > 0 && (
                    <Button
                      size="small"
                      variant="outlined"
                      color="primary"
                      disabled={exportingId === event.id}
                      startIcon={exportingId === event.id ? <CircularProgress size={16} /> : <DownloadIcon />}
                      onClick={async (e) => {
                        e.stopPropagation();
                        try {
                          setExportingId(event.id);
                          await exportEventMembers(event.id);
                          setSnackbar({
                            open: true,
                            message: `Successfully exported members for "${event.eventName}"`,
                            severity: 'success'
                          });
                        } catch (error) {
                          console.error('Error exporting members:', error);
                          setSnackbar({
                            open: true,
                            message: 'Failed to export members. Please try again.',
                            severity: 'error'
                          });
                        } finally {
                          setExportingId(null);
                        }
                      }}
                    >
                      Export
                    </Button>
                  )}
                </Box>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>

      {totalPages > 1 && (
        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            color="primary"
            size="large"
          />
        </Box>
      )}
      
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={() => setSnackbar({...snackbar, open: false})}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setSnackbar({...snackbar, open: false})} 
          severity={snackbar.severity} 
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default SchoolEventsList;