import React, { useState } from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  CardActionArea,
  Tooltip
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import DescriptionIcon from '@mui/icons-material/Description';
import ImageIcon from '@mui/icons-material/Image';
import { galleryService } from '../../services/galleryService';

const MediaItem = ({ media, onDelete, onDetailsUpdated }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
  const [mediaDetails, setMediaDetails] = useState({
    title: media.title || '',
    description: media.description || ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleMenuOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (event) => {
    if (event) event.stopPropagation();
    setAnchorEl(null);
  };

  const handleEditClick = (event) => {
    event.stopPropagation();
    handleMenuClose();
    setDetailsDialogOpen(true);
  };

  const handleDeleteClick = (event) => {
    event.stopPropagation();
    handleMenuClose();
    onDelete(media);
  };

  const handleDetailsChange = (e) => {
    const { name, value } = e.target;
    setMediaDetails(prev => ({ ...prev, [name]: value }));
  };

  const handleDetailsSubmit = async () => {
    setIsSubmitting(true);
    try {
      const response = await galleryService.updateMediaDetails(media.id, mediaDetails);
      console.log('Update media details response:', response);
      setDetailsDialogOpen(false);
      if (onDetailsUpdated) {
        onDetailsUpdated({ ...media, ...mediaDetails });
      }
    } catch (error) {
      console.error('Failed to update media details:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handlePreviewClick = () => {
    setPreviewDialogOpen(true);
  };

  // Helper to get appropriate media icon
  const getMediaIcon = () => {
    switch (media.mediaType) {
      case 'IMAGE':
        return <ImageIcon sx={{ fontSize: 80, color: '#5E35B1' }} />;
      case 'VIDEO':
        return <VideoFileIcon sx={{ fontSize: 80, color: '#5E35B1' }} />;
      case 'PDF':
        return <DescriptionIcon sx={{ fontSize: 80, color: '#5E35B1' }} />;
      default:
        return <InsertDriveFileIcon sx={{ fontSize: 80, color: '#5E35B1' }} />;
    }
  };

  // Helper to render appropriate preview content
  const renderPreview = () => {
    switch (media.mediaType) {
      case 'IMAGE':
        return (
          <img 
            src={media.fileUrl} 
            alt={media.title || 'Image preview'} 
            style={{ width: '100%', maxHeight: '80vh', objectFit: 'contain' }}
          />
        );
      case 'VIDEO':
        return (
          <video 
            controls 
            width="100%" 
            style={{ maxHeight: '80vh' }}
          >
            <source src={media.fileUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        );
      case 'PDF':
        return (
          <iframe
            src={media.fileUrl}
            title={media.title || 'PDF Document'}
            width="100%"
            height="80vh"
            style={{ border: 'none' }}
          />
        );
      default:
        return (
          <Box 
            sx={{ 
              p: 4, 
              textAlign: 'center',
              backgroundColor: 'rgba(94, 53, 177, 0.1)',
              borderRadius: 1
            }}
          >
            <InsertDriveFileIcon sx={{ fontSize: 120, color: '#5E35B1', mb: 2 }} />
            <Typography variant="body1">
              <a href={media.fileUrl} target="_blank" rel="noopener noreferrer">
                Download File
              </a>
            </Typography>
          </Box>
        );
    }
  };

  return (
    <>
      <Card 
        sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          height: '100%',
          position: 'relative' 
        }}
      >
        <CardActionArea onClick={handlePreviewClick}>
          {/* Display image or thumbnail or icon based on media type */}
          {media.mediaType === 'IMAGE' && media.fileUrl ? (
            <CardMedia
              component="img"
              height="160"
              image={media.fileUrl}
              alt={media.title || 'Media item'}
            />
          ) : media.mediaType === 'VIDEO' && media.thumbnailUrl ? (
            <Box sx={{ position: 'relative' }}>
              <CardMedia
                component="img"
                height="160"
                image={media.thumbnailUrl}
                alt={media.title || 'Video thumbnail'}
              />
              <Box 
                sx={{ 
                  position: 'absolute', 
                  top: 0, left: 0, right: 0, bottom: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'rgba(0, 0, 0, 0.3)'
                }}
              >
                <VideoFileIcon sx={{ fontSize: 60, color: 'white' }} />
              </Box>
            </Box>
          ) : (
            <Box 
              sx={{ 
                height: 160, 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center',
                backgroundColor: 'rgba(94, 53, 177, 0.1)' 
              }}
            >
              {getMediaIcon()}
            </Box>
          )}
          <CardContent sx={{ flexGrow: 1 }}>
            <Typography gutterBottom variant="h6" component="div" noWrap>
              {media.title || (media.mediaType === 'IMAGE' ? 'Image' : media.mediaType === 'VIDEO' ? 'Video' : 'Document')}
            </Typography>
            {media.description && (
              <Typography variant="body2" color="text.secondary" sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
              }}>
                {media.description}
              </Typography>
            )}
          </CardContent>
        </CardActionArea>
        
        <Box sx={{ position: 'absolute', top: 8, right: 8 }}>
          <Tooltip title="Options">
            <IconButton 
              onClick={handleMenuOpen}
              sx={{ 
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.9)',
                }
              }}
              size="small"
            >
              <MoreVertIcon />
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleEditClick}>Edit Details</MenuItem>
            <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
          </Menu>
        </Box>
      </Card>

      {/* Edit Details Dialog */}
      <Dialog open={detailsDialogOpen} onClose={() => setDetailsDialogOpen(false)} fullWidth maxWidth="sm">
        <DialogTitle>Edit Media Details</DialogTitle>
        <DialogContent>
          <TextField
            label="Title"
            name="title"
            value={mediaDetails.title}
            onChange={handleDetailsChange}
            fullWidth
            variant="outlined"
            margin="normal"
          />
          <TextField
            label="Description"
            name="description"
            value={mediaDetails.description}
            onChange={handleDetailsChange}
            fullWidth
            variant="outlined"
            margin="normal"
            multiline
            rows={4}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDetailsDialogOpen(false)}>Cancel</Button>
          <Button 
            onClick={handleDetailsSubmit} 
            variant="contained" 
            color="primary"
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Saving...' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Preview Dialog */}
      <Dialog open={previewDialogOpen} onClose={() => setPreviewDialogOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle>
          {media.title || 'Media Preview'}
          <IconButton
            aria-label="close"
            onClick={() => setPreviewDialogOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8
            }}
          >
            <MoreVertIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {renderPreview()}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPreviewDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MediaItem;