import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Alert,
  Typography,
  Snackbar,
} from '@mui/material';
import { ParticipationService, PARTICIPATION_TYPES } from '../../services/participationService';
import StatsOverview from '../../components/participation/StatsOverview';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import SchoolSelector from '../../components/SchoolSelector';

const ParticipationDashboard = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [stats, setStats] = useState(null);
  const [exportLoading, setExportLoading] = useState({
    interests: false,
    activities: false,
    contributors: false,
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  
  const [filters, setFilters] = useState({
    participationType: 'VOLUNTEER',
    udiseCode: 'all',
  });

  useEffect(() => {
    if (isAuthenticated) {
      fetchStats();
    }
  }, [filters, isAuthenticated]);

  const fetchStats = async () => {
    try {
      setLoading(true);
      setError(null);

      // Only include udiseCode in filters if it's not null/undefined
      const filterParams = {
        participationType: filters.participationType,
        ...(filters.udiseCode && filters.udiseCode !== 'all' && { udiseCode: filters.udiseCode }),
      };

      const statsData = await ParticipationService.getParticipationStats(
        filters.udiseCode || 'all', 
        filterParams
      );
      console.log('Stats:', statsData);
      setStats(statsData);
      
    } catch (error) {
      console.error('Error fetching stats:', error);
      if (error.response?.status === 403) {
        navigate('/login');
      } else {
        setError('Failed to load participation data. Please try again later.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleParticipationTypeChange = (event) => {
    setFilters(prev => ({
      ...prev,
      participationType: event.target.value
    }));
  };

  const handleSchoolSelect = (udiseCode) => {
    setFilters(prev => ({
      ...prev,
      udiseCode: udiseCode || 'all'
    }));
  };

  const handleExportInterests = async () => {
    console.log('Exporting interests...');
    try {
      setExportLoading(prev => ({ ...prev, interests: true }));
      await ParticipationService.exportInterests(
        filters.udiseCode || 'all',
        filters.participationType || ''
      );
      setSnackbar({
        open: true,
        message: 'Interests data exported successfully!',
        severity: 'success'
      });
    } catch (error) {
      console.error('Export interests error:', error);
      setSnackbar({
        open: true,
        message: 'Failed to export interests. Please try again.',
        severity: 'error'
      });
    } finally {
      setExportLoading(prev => ({ ...prev, interests: false }));
    }
  };

  const handleExportActivities = async () => {
    try {
      setExportLoading(prev => ({ ...prev, activities: true }));
      await ParticipationService.exportActivities(
        filters.udiseCode || 'all',
        filters.participationType || ''
      );
      setSnackbar({
        open: true,
        message: 'Activities data exported successfully!',
        severity: 'success'
      });
    } catch (error) {
      console.error('Export activities error:', error);
      setSnackbar({
        open: true,
        message: 'Failed to export activities. Please try again.',
        severity: 'error'
      });
    } finally {
      setExportLoading(prev => ({ ...prev, activities: false }));
    }
  };

  const handleExportContributors = async () => {
    try {
      setExportLoading(prev => ({ ...prev, contributors: true }));
      await ParticipationService.exportContributors(
        filters.udiseCode || 'all',
        filters.participationType || ''
      );
      setSnackbar({
        open: true,
        message: 'Contributors data exported successfully!',
        severity: 'success'
      });
    } catch (error) {
      console.error('Export contributors error:', error);
      setSnackbar({
        open: true,
        message: 'Failed to export contributors. Please try again.',
        severity: 'error'
      });
    } finally {
      setExportLoading(prev => ({ ...prev, contributors: false }));
    }
  };

  if (!isAuthenticated) {
    return <div>Please log in to view this page.</div>;
  }

  return (
    <Container maxWidth="xl">
      <Box sx={{ py: 4 }}>
        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}
        
        {/* Header */}
        <Typography variant="h4" component="h1" gutterBottom>
          Participation Dashboard
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph>
          View and analyze participation statistics across all schools or filter by specific school
        </Typography>

        {/* Filters Section */}
        <Paper sx={{ p: 3, mb: 4 }}>
          <Grid container spacing={3} alignItems="flex-start">
            <Grid item xs={12} md={6}>
              <SchoolSelector 
                onSchoolSelect={handleSchoolSelect}
                selectedUdiseCode={filters.udiseCode !== 'all' ? filters.udiseCode : null}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Participation Type</InputLabel>
                <Select
                  value={filters.participationType}
                  label="Participation Type"
                  onChange={handleParticipationTypeChange}
                >
                  <MenuItem value="">All Types</MenuItem>
                  {Object.entries(PARTICIPATION_TYPES).map(([key, value]) => (
                    <MenuItem key={key} value={value}>
                      {value.replace('_', ' ')}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Paper>

        {/* Stats Overview Section */}
        <Box sx={{ mb: 4 }}>
          {console.log('Rendering StatsOverview with:', {
            stats,
            loading,
            filters,
            exportLoading
          })}
          <StatsOverview 
            stats={stats} 
            loading={loading} 
            filters={filters}
            onExportInterests={handleExportInterests}
            onExportActivities={handleExportActivities}
            onExportContributors={handleExportContributors}
            exportLoadingState={exportLoading}
          />
        </Box>

        <Snackbar 
          open={snackbar.open} 
          autoHideDuration={6000} 
          onClose={() => setSnackbar({...snackbar, open: false})}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert 
            onClose={() => setSnackbar({...snackbar, open: false})} 
            severity={snackbar.severity} 
            variant="filled"
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </Container>
  );
};

export default ParticipationDashboard;