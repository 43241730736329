import React, { useState, useEffect } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Typography,
  Alert,
  TextField,
  Button,
  Stack,
  IconButton,
  Tooltip,
  Switch,
  FormGroup,
  FormControlLabel,
  Autocomplete,
  Paper,
  InputAdornment,
} from '@mui/material';
import { 
  Close as CloseIcon, 
  School as SchoolIcon,
  Search as SearchIcon,
  LocationOn as LocationIcon,
  Domain as DomainIcon,
  Clear as ClearIcon
} from '@mui/icons-material';
import axios from 'axios';

// Create a separate axios instance for school APIs
const schoolApi = axios.create({
  baseURL: 'https://nammaschool1.tnschools.gov.in/api/pavillion',
  headers: {
    'Content-Type': 'application/json',
  }
});

const SchoolSelector = ({ onSchoolSelect, selectedUdiseCode = null }) => {
  const [districts, setDistricts] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [schools, setSchools] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedBlock, setSelectedBlock] = useState(null);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [directUdiseInput, setDirectUdiseInput] = useState('');
  const [useDirectInput, setUseDirectInput] = useState(false);
  
  // Loading states for each dropdown
  const [districtsLoading, setDistrictsLoading] = useState(false);
  const [blocksLoading, setBlocksLoading] = useState(false);
  const [schoolsLoading, setSchoolsLoading] = useState(false);

  useEffect(() => {
    fetchDistricts();
  }, []);

  useEffect(() => {
    if (selectedDistrict) {
      fetchBlocks(selectedDistrict.id);
      setSelectedBlock(null);
      setSelectedSchool(null);
      setSchools([]);
    }
  }, [selectedDistrict]);

  useEffect(() => {
    if (selectedDistrict && selectedBlock) {
      fetchSchools(selectedDistrict.id, selectedBlock.block_id);
    }
  }, [selectedBlock]);

  // Effect to handle preselected UDISE code
  useEffect(() => {
    if (selectedUdiseCode && schools.length > 0) {
      const school = schools.find(s => s.udise_code === selectedUdiseCode);
      if (school) {
        setSelectedSchool(school);
      }
    }
  }, [selectedUdiseCode, schools]);

  const fetchDistricts = async () => {
    try {
      setDistrictsLoading(true);
      const response = await schoolApi.post('/get-district-list');
      if (response.data.status === 'success' && Array.isArray(response.data.data)) {
        setDistricts(response.data.data);
      } else {
        throw new Error('Invalid district data format');
      }
    } catch (err) {
      setError('Failed to fetch districts');
      console.error('Fetch districts error:', err);
    } finally {
      setDistrictsLoading(false);
    }
  };

  const fetchBlocks = async (districtId) => {
    try {
      setBlocksLoading(true);
      const response = await schoolApi.post('/get-block-list', {
        district_id: districtId
      });
      if (response.data.status === 'success' && Array.isArray(response.data.data)) {
        setBlocks(response.data.data);
      } else {
        throw new Error('Invalid block data format');
      }
    } catch (err) {
      setError('Failed to fetch blocks');
      console.error('Fetch blocks error:', err);
    } finally {
      setBlocksLoading(false);
    }
  };

  const fetchSchools = async (districtId, blockId) => {
    try {
      setSchoolsLoading(true);
      const response = await schoolApi.post('/get-school-list', {
        district_id: districtId,
        block_id: blockId
      });
      if (response.data.status === 'success' && Array.isArray(response.data.data)) {
        setSchools(response.data.data);
      } else {
        throw new Error('Invalid school data format');
      }
    } catch (err) {
      setError('Failed to fetch schools');
      console.error('Fetch schools error:', err);
    } finally {
      setSchoolsLoading(false);
    }
  };

  const handleSchoolChange = (event, newValue) => {
    setSelectedSchool(newValue);
    if (newValue) {
      onSchoolSelect(newValue.udise_code);
    } else {
      onSchoolSelect(null);
    }
  };

  const handleDirectUdiseSubmit = () => {
    if (directUdiseInput.trim()) {
      onSchoolSelect(directUdiseInput.trim());
    }
  };

  const handleReset = () => {
    setSelectedDistrict(null);
    setSelectedBlock(null);
    setSelectedSchool(null);
    setDirectUdiseInput('');
    onSchoolSelect(null);
  };

  const isValidUdiseCode = (code) => {
    // UDISE code validation logic
    return /^\d{11}$/.test(code);
  };

  if (loading && districts.length === 0) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {/* Mode Switch */}
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={useDirectInput}
              onChange={(e) => setUseDirectInput(e.target.checked)}
            />
          }
          label="Direct UDISE Input"
        />
      </FormGroup>

      {useDirectInput ? (
        // Direct UDISE Input Mode
        <Stack direction="row" spacing={2} alignItems="center">
          <TextField
            fullWidth
            label="UDISE Code"
            value={directUdiseInput}
            onChange={(e) => setDirectUdiseInput(e.target.value)}
            error={directUdiseInput && !isValidUdiseCode(directUdiseInput)}
            helperText={directUdiseInput && !isValidUdiseCode(directUdiseInput) ? "Invalid UDISE code" : ""}
            placeholder="Enter 11-digit UDISE code"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SchoolIcon />
                </InputAdornment>
              ),
              endAdornment: directUdiseInput && (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={() => setDirectUdiseInput('')}>
                    <ClearIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            onClick={handleDirectUdiseSubmit}
            disabled={!isValidUdiseCode(directUdiseInput)}
          >
            Apply
          </Button>
        </Stack>
      ) : (
        // Dropdown Selection Mode with Autocomplete
        <>
          {/* District Selection */}
          <Autocomplete
            value={selectedDistrict}
            onChange={(event, newValue) => {
              setSelectedDistrict(newValue);
            }}
            options={districts}
            loading={districtsLoading}
            getOptionLabel={(option) => option.district_name || ''}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="District"
                placeholder="Search for a district..."
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      <InputAdornment position="start">
                        <LocationIcon color="action" />
                      </InputAdornment>
                      {params.InputProps.startAdornment}
                    </>
                  ),
                  endAdornment: (
                    <>
                      {districtsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            renderOption={(props, option) => (
              <li {...props}>
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  <LocationIcon sx={{ color: 'text.secondary', mr: 1 }} fontSize="small" />
                  <Typography variant="body2">{option.district_name}</Typography>
                </Box>
              </li>
            )}
          />

          {/* Block Selection */}
          <Autocomplete
            value={selectedBlock}
            onChange={(event, newValue) => {
              setSelectedBlock(newValue);
            }}
            options={blocks}
            disabled={!selectedDistrict}
            loading={blocksLoading}
            getOptionLabel={(option) => option.block_name || ''}
            isOptionEqualToValue={(option, value) => option.block_id === value.block_id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Block"
                placeholder={selectedDistrict ? "Search for a block..." : "First select a district"}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      <InputAdornment position="start">
                        <DomainIcon color="action" />
                      </InputAdornment>
                      {params.InputProps.startAdornment}
                    </>
                  ),
                  endAdornment: (
                    <>
                      {blocksLoading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            renderOption={(props, option) => (
              <li {...props}>
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  <DomainIcon sx={{ color: 'text.secondary', mr: 1 }} fontSize="small" />
                  <Typography variant="body2">{option.block_name}</Typography>
                </Box>
              </li>
            )}
          />

          {/* School Selection */}
          <Autocomplete
            value={selectedSchool}
            onChange={handleSchoolChange}
            options={schools}
            disabled={!selectedBlock}
            loading={schoolsLoading}
            getOptionLabel={(option) => `${option.school_name} (${option.school_type})` || ''}
            isOptionEqualToValue={(option, value) => option.udise_code === value.udise_code}
            renderInput={(params) => (
              <TextField
                {...params}
                label="School"
                placeholder={selectedBlock ? "Search for a school..." : "First select a block"}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      <InputAdornment position="start">
                        <SchoolIcon color="action" />
                      </InputAdornment>
                      {params.InputProps.startAdornment}
                    </>
                  ),
                  endAdornment: (
                    <>
                      {schoolsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            renderOption={(props, option) => (
              <li {...props}>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <Typography variant="body2">
                    {option.school_name}
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
                    <Typography variant="caption" color="text.secondary" sx={{ mr: 1 }}>
                      {option.school_type}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      UDISE: {option.udise_code}
                    </Typography>
                  </Box>
                </Box>
              </li>
            )}
          />
        </>
      )}

      {/* Selected School Info */}
      {selectedUdiseCode && (
        <Box sx={{ mt: 2 }}>
          <Alert 
            severity="info" 
            icon={<SchoolIcon />}
            action={
              <Tooltip title="Clear Selection">
                <IconButton
                  aria-label="clear"
                  color="inherit"
                  size="small"
                  onClick={handleReset}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            }
          >
            <Typography variant="body2">
              <strong>Selected School:</strong> {selectedSchool ? selectedSchool.school_name : selectedUdiseCode}
            </Typography>
            {selectedSchool && (
              <Typography variant="caption" display="block">
                UDISE: {selectedSchool.udise_code} | Type: {selectedSchool.school_type}
              </Typography>
            )}
          </Alert>
        </Box>
      )}


      {loading && <CircularProgress size={20} />}
    </Box>
  );
};

export default SchoolSelector;