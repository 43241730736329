import React from 'react';
import {
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {
  Edit,
  Delete,
  Security,
} from '@mui/icons-material';

const UserActionsMenu = ({
  anchorEl,
  onClose,
  onEdit,
  onDelete,
  onManageRoles,
}) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <MenuItem onClick={onManageRoles}>
        <ListItemIcon>
          <Security fontSize="small" />
        </ListItemIcon>
        <ListItemText>Manage Roles</ListItemText>
      </MenuItem>
      <MenuItem onClick={onEdit}>
        <ListItemIcon>
          <Edit fontSize="small" />
        </ListItemIcon>
        <ListItemText>Edit User</ListItemText>
      </MenuItem>
      <MenuItem onClick={onDelete}>
        <ListItemIcon>
          <Delete fontSize="small" color="error" />
        </ListItemIcon>
        <ListItemText sx={{ color: 'error.main' }}>Delete User</ListItemText>
      </MenuItem>
    </Menu>
  );
};

export default UserActionsMenu;