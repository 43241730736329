import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField
} from '@mui/material';
import { getReportsForPost, getHighlyReportedPosts } from '../services/postReportService';

function ReportedPosts() {
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalElements, setTotalElements] = useState(0);
  const [selectedPost, setSelectedPost] = useState(null);
  const [postReports, setPostReports] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [minReportCount, setMinReportCount] = useState(5);
  // New state for report details pagination
  const [reportPage, setReportPage] = useState(0);
  const [reportRowsPerPage, setReportRowsPerPage] = useState(10);
  const [totalReports, setTotalReports] = useState(0);

  const fetchReports = async () => {
    try {
      setLoading(true);
      const response = await getHighlyReportedPosts(page, rowsPerPage, minReportCount);
      setReports(response.data.content || []);
      setTotalElements(response.data.totalElements || 0);
      
      const maxPage = Math.ceil(response.data.totalElements / rowsPerPage) - 1;
      if (page > maxPage && maxPage >= 0) {
        setPage(maxPage);
      }
    } catch (err) {
      setError('Failed to fetch reports. Please try again.');
      console.error('Fetch reports error:', err);
    } finally {
      setLoading(false);
    }
  };

  const fetchPostReports = async (postId) => {
    try {
      const response = await getReportsForPost(postId, reportPage, reportRowsPerPage);
      setPostReports(response.data.content || []);
      setTotalReports(response.data.totalElements || 0);
    } catch (err) {
      setError('Failed to fetch post reports. Please try again.');
      console.error('Fetch post reports error:', err);
    }
  };

  useEffect(() => {
    fetchReports();
  }, [page, rowsPerPage, minReportCount]);

  useEffect(() => {
    if (selectedPost && dialogOpen) {
      fetchPostReports(selectedPost);
    }
  }, [selectedPost, reportPage, reportRowsPerPage, dialogOpen]);

  const handleViewReports = async (postId) => {
    setSelectedPost(postId);
    setReportPage(0);
    setDialogOpen(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleReportChangePage = (event, newPage) => {
    setReportPage(newPage);
  };

  const handleReportChangeRowsPerPage = (event) => {
    setReportRowsPerPage(parseInt(event.target.value, 10));
    setReportPage(0);
  };

  const handleMinReportCountChange = (event) => {
    const value = parseInt(event.target.value, 10);
    if (value > 0) {
      setMinReportCount(value);
      setPage(0);
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedPost(null);
    setReportPage(0);
    setPostReports([]);
  };

  if (loading && reports.length === 0) {
    return (
      <Container maxWidth="md" sx={{ textAlign: 'center', mt: 4 }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Reported Posts
        </Typography>

        {error && (
          <Typography color="error" align="center" sx={{ mb: 2 }}>
            {error}
          </Typography>
        )}
        
        <Box sx={{ mb: 3 }}>
          <TextField
            type="number"
            label="Minimum Report Count"
            value={minReportCount}
            onChange={handleMinReportCountChange}
            size="small"
            InputProps={{ inputProps: { min: 1 } }}
          />
        </Box>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell><strong>Post ID</strong></TableCell>
                <TableCell><strong>Report Count</strong></TableCell>
                <TableCell><strong>Actions</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reports.map((report) => (
                <TableRow key={report.postId}>
                  <TableCell>{report.postId}</TableCell>
                  <TableCell>{report.reportCount || 'N/A'}</TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => handleViewReports(report.postId)}
                    >
                      View Reports
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              {reports.length === 0 && (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    <Typography variant="body1" sx={{ py: 2 }}>
                      {loading ? 'Loading reports...' : 'No reports found'}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalElements}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>

        <Dialog 
          open={dialogOpen} 
          onClose={handleCloseDialog} 
          maxWidth="md" 
          fullWidth
        >
          <DialogTitle>
            Reports for Post {selectedPost}
          </DialogTitle>
          <DialogContent>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell><strong>Reason</strong></TableCell>
                    <TableCell><strong>Date</strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {postReports.map((report) => (
                    <TableRow key={report.id}>
                      <TableCell>{report.reason}</TableCell>
                      <TableCell>
                        {new Date(report.createdAt).toLocaleString()}
                      </TableCell>
                    </TableRow>
                  ))}
                  {postReports.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={2} align="center">
                        <Typography variant="body1" sx={{ py: 2 }}>
                          No detailed reports found
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={totalReports}
                rowsPerPage={reportRowsPerPage}
                page={reportPage}
                onPageChange={handleReportChangePage}
                onRowsPerPageChange={handleReportChangeRowsPerPage}
              />
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Close</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Container>
  );
}

export default ReportedPosts;