import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  IconButton,
  CircularProgress,
  Box,
  Avatar,
} from '@mui/material';
import { 
  Edit as EditIcon,
  Delete as DeleteIcon,
  Group as GroupIcon,
  PhotoLibrary as GalleryIcon,
} from '@mui/icons-material';

import { useNavigate } from 'react-router-dom';

const ChatGroupList = ({
  groups,
  page,
  rowsPerPage,
  loading,
  onPageChange,
  onRowsPerPageChange,
  onEditGroup,
  onDeleteGroup,
  onViewMembers,
  totalElements,
  onViewGallery,
}) => {
  const navigate = useNavigate();
  
  const handleGalleryClick = (groupId, e) => {
    e.stopPropagation();
    console.log(`Navigating to gallery for group: ${groupId}`);
    navigate(`/chat-gallery/groups/${groupId}`);
  };
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Paper sx={{ width: '100%', mb: 2 }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><strong>Group</strong></TableCell>
              <TableCell><strong>Description</strong></TableCell>
              <TableCell align="center"><strong>Members</strong></TableCell>
              <TableCell align="center"><strong>Chat Enabled</strong></TableCell>
              <TableCell><strong>Created Date</strong></TableCell>
              <TableCell align="right"><strong>Actions</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groups.map((group) => (
              <TableRow key={group.id}>
                <TableCell>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Avatar
                      src={group.profilePictureUrl}
                      alt={group.name}
                      sx={{ width: 40, height: 40 }}
                    >
                      {group.name?.charAt(0)}
                    </Avatar>
                    {group.name}
                  </Box>
                </TableCell>
                <TableCell>{group.description}</TableCell>
                <TableCell align="center">{group.memberCount}</TableCell>
                <TableCell align="center">{group.allowChat ? 'Yes' : 'No'}</TableCell>
                <TableCell>
                  {new Date(group.createdDate).toLocaleDateString()}
                </TableCell>
                <TableCell align="right">
                  <IconButton 
                    onClick={() => onViewMembers(group)}
                    size="small"
                    title="View Members"
                  >
                    <GroupIcon />
                  </IconButton>
                  <IconButton 
                    onClick={(e) => handleGalleryClick(group.id, e)}
                    size="small"
                    title="View Gallery"
                    sx={{ color: '#5E35B1' }}
                  >
                    <GalleryIcon />
                  </IconButton>
                  <IconButton 
                    onClick={() => onEditGroup(group)}
                    size="small"
                    title="Edit Group"
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => onDeleteGroup(group.id)}
                    size="small"
                    color="error"
                    title="Delete Group"
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {groups.length === 0 && (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No groups found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalElements}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 50]}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </Paper>
  );
};

export default ChatGroupList;