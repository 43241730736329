import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Alert,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';

// Custom hooks
import { useUserManagement } from '../hooks/useUserManagement';

// Components
import DataTable from '../components/shared/DataTable';
import UserActionsMenu from '../components/users/UserActionsMenu';
import UserRoleManager from '../components/users/roleManagement/UserRoleManager';
import UserTableRow from '../components/users/list/UserTableRow';
import UserListHeader from '../components/users/list/UserListHeader';

// Constants
import { USER_TABLE_COLUMNS } from '../components/users/list/constants';

const UserList = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [actionMenuAnchor, setActionMenuAnchor] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [roleManagerOpen, setRoleManagerOpen] = useState(false);
  const [roleManagerError, setRoleManagerError] = useState('');

  const {
    users,
    loading,
    searchLoading,
    error,
    successMessage,
    page,
    rowsPerPage,
    totalElements,
    setPage,
    setRowsPerPage,
    setError,
    searchUserByPhone,
    handleDeleteUser,
    handleRolesUpdate,
  } = useUserManagement();

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    setError('');

    const phoneNumberPattern = /^\d{10}$/;
    if (phoneNumberPattern.test(value)) {
      searchUserByPhone(value);
    }
  };

  const handleClearSearch = () => {
    setSearchTerm('');
  };

  const handleActionMenuOpen = (event, user) => {
    setActionMenuAnchor(event.currentTarget);
    setSelectedUser(user);
  };

  const handleActionMenuClose = () => {
    setActionMenuAnchor(null);
  };

  const clearUserSelection = () => {
    setSelectedUser(null);
    setActionMenuAnchor(null);
  };

  const handleEditUser = () => {
    navigate(`/edit-user/${selectedUser.id}`);
    clearUserSelection();
  };

  const handleUserDelete = () => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      handleDeleteUser(selectedUser.id);
    }
    clearUserSelection();
  };

  const handleOpenRoleManager = () => {
    setRoleManagerOpen(true);
    setRoleManagerError('');
    handleActionMenuClose();
  };

  const handleCloseRoleManager = () => {
    setRoleManagerOpen(false);
    setRoleManagerError('');
    clearUserSelection();
  };

  const handleUserRolesUpdate = (newRoles) => {
    handleRolesUpdate(selectedUser.id, newRoles);
    handleCloseRoleManager();
  };

  const handleRoleManagerError = (error) => {
    setRoleManagerError(error);
  };

  const filteredUsers = users.filter((user) =>
    Object.values(user).some(
      (value) =>
        value && 
        value.toString().toLowerCase().includes(searchTerm.toLowerCase()) &&
        value !== '[object Object]'
    )
  );

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4 }}>
        <Box sx={{ mb: 2 }}>
          <Collapse in={!!error}>
            <Alert severity="error" sx={{ mb: error ? 2 : 0 }}>
              {error}
            </Alert>
          </Collapse>
          <Collapse in={!!successMessage}>
            <Alert severity="success" sx={{ mb: successMessage ? 2 : 0 }}>
              {successMessage}
            </Alert>
          </Collapse>
        </Box>

        <UserListHeader
          searchTerm={searchTerm}
          onSearchChange={handleSearchChange}
          onClearSearch={handleClearSearch}
          searchLoading={searchLoading}
          onCreateUser={() => navigate('/create-user')}
        />

        <DataTable
          columns={USER_TABLE_COLUMNS}
          data={filteredUsers}
          loading={loading}
          page={page}
          rowsPerPage={rowsPerPage}
          totalElements={totalElements}
          onPageChange={(e, newPage) => setPage(newPage)}
          onRowsPerPageChange={(e) => {
            setRowsPerPage(parseInt(e.target.value, 10));
            setPage(0);
          }}
          renderRow={(user) => (
            <UserTableRow
              key={user.id}
              user={user}
              onActionClick={handleActionMenuOpen}
            />
          )}
          emptyMessage="No users found"
        />

        <UserActionsMenu
          anchorEl={actionMenuAnchor}
          onClose={handleActionMenuClose}
          onEdit={handleEditUser}
          onDelete={handleUserDelete}
          onManageRoles={handleOpenRoleManager}
        />

        <Dialog 
          open={roleManagerOpen} 
          onClose={handleCloseRoleManager}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>
            {selectedUser?.displayName ? `Manage Roles: ${selectedUser.displayName}` : 'Manage Roles'}
          </DialogTitle>
          <DialogContent dividers>
            {roleManagerError && (
              <Alert severity="error" sx={{ mb: 2 }} onClose={() => setRoleManagerError('')}>
                {roleManagerError}
              </Alert>
            )}
            <UserRoleManager
              phoneNumber={selectedUser?.phoneNumber || ''}
              currentRoles={selectedUser?.roles || ['USER']}
              onRolesUpdate={handleUserRolesUpdate}
              onError={handleRoleManagerError}
            />
          </DialogContent>
        </Dialog>
      </Box>
    </Container>
  );
};

export default UserList;