import React, { useState, useEffect } from 'react';
import {
  Button,
  Chip,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Typography,
} from '@mui/material';
import { assignRole, removeRole } from '../../../services/userService';

const AVAILABLE_ROLES = [
  'ADMIN',
  'CONTENT_CREATOR',
  'SCHOOL_CONTENT_CREATOR',
  'USER_MANAGER',
  'SCHOOL_EVENT_MANAGER',
  'SMC_EVENT_MANAGER',
  'GROUP_CHAT_MANAGER'
];

const UserRoleManager = ({ phoneNumber = '', currentRoles = [], onRolesUpdate, onError }) => {
  const [selectedRole, setSelectedRole] = useState('');
  const [loading, setLoading] = useState(false);
  const [localRoles, setLocalRoles] = useState(['USER']);

  useEffect(() => {
    if (currentRoles && currentRoles.length > 0) {
      setLocalRoles(currentRoles);
    } else {
      setLocalRoles(['USER']);
    }
  }, [currentRoles]);

  // Reset selected role when phone number changes
  useEffect(() => {
    setSelectedRole('');
  }, [phoneNumber]);

  // Available roles excluding current roles
  const availableRolesToAdd = AVAILABLE_ROLES.filter(
    role => !localRoles.includes(role)
  );

  const handleAddRole = async () => {
    if (!selectedRole || !phoneNumber) {
      onError('Phone number is required to manage roles');
      return;
    }

    setLoading(true);
    try {
      await assignRole(phoneNumber, selectedRole);
      const newRoles = [...localRoles, selectedRole];
      setLocalRoles(newRoles);
      onRolesUpdate(newRoles);
      setSelectedRole('');
    } catch (error) {
      onError(error.response?.data?.message || 'Failed to assign role');
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveRole = async (role) => {
    if (role === 'USER' || !phoneNumber) return;
    
    setLoading(true);
    try {
      await removeRole(phoneNumber, role);
      const newRoles = localRoles.filter(r => r !== role);
      setLocalRoles(newRoles.length > 0 ? newRoles : ['USER']);
      onRolesUpdate(newRoles.length > 0 ? newRoles : ['USER']);
    } catch (error) {
      onError(error.response?.data?.message || 'Failed to remove role');
    } finally {
      setLoading(false);
    }
  };

  if (!phoneNumber) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <Typography color="error">
          Error: No phone number provided
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%', pt: 2 }}>
      {/* Current Roles */}
      <Box sx={{ mb: 3 }}>
        <Typography variant="subtitle1" sx={{ mb: 1.5 }}>Current Roles:</Typography>
        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
          {localRoles.map((role) => (
            <Chip
              key={role}
              label={role}
              onDelete={role === 'USER' ? undefined : () => handleRemoveRole(role)}
              color="primary"
              variant={role === 'USER' ? 'filled' : 'outlined'}
              size="small"
              disabled={loading}
            />
          ))}
        </Box>
      </Box>

      {/* Add New Role */}
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'flex-end' }}>
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel id="role-select-label">Add Role</InputLabel>
          <Select
            labelId="role-select-label"
            value={selectedRole}
            label="Add Role"
            onChange={(e) => setSelectedRole(e.target.value)}
            size="small"
            disabled={loading || availableRolesToAdd.length === 0}
          >
            {availableRolesToAdd.map((role) => (
              <MenuItem key={role} value={role}>
                {role}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          onClick={handleAddRole}
          disabled={!selectedRole || loading}
          size="small"
          sx={{ height: 40 }}
        >
          {loading ? <CircularProgress size={24} /> : 'Add Role'}
        </Button>
      </Box>

      {availableRolesToAdd.length === 0 && (
        <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
          All available roles have been assigned
        </Typography>
      )}
    </Box>
  );
};

export default UserRoleManager;