import React, { useState } from 'react';
import { TextField, Button, Box, Typography, CircularProgress } from '@mui/material';
import { resetPassword } from '../../services/authService';

const ResetPassword = ({ phoneNumber, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [formData, setFormData] = useState({
    otp: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [validationErrors, setValidationErrors] = useState({
    otp: '',
    newPassword: '',
    confirmPassword: ''
  });

  const validateField = (name, value) => {
    switch (name) {
      case 'otp':
        if (value && !/^\d{6}$/.test(value)) {
          return 'OTP must be exactly 6 digits';
        }
        break;
      case 'newPassword':
        if (value && value.length < 8) {
          return 'Password must be at least 8 characters long';
        }
        if (formData.confirmPassword && value !== formData.confirmPassword) {
          setValidationErrors(prev => ({
            ...prev,
            confirmPassword: 'Passwords do not match'
          }));
        } else if (formData.confirmPassword) {
          setValidationErrors(prev => ({
            ...prev,
            confirmPassword: ''
          }));
        }
        break;
      case 'confirmPassword':
        if (value !== formData.newPassword) {
          return 'Passwords do not match';
        }
        break;
      default:
        return '';
    }
    return '';
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));

    // Only show validation errors after user interaction
    const error = validateField(name, value);
    setValidationErrors(prev => ({
      ...prev,
      [name]: error
    }));
  };

  const validateForm = () => {
    const errors = {
      otp: validateField('otp', formData.otp),
      newPassword: validateField('newPassword', formData.newPassword),
      confirmPassword: validateField('confirmPassword', formData.confirmPassword)
    };

    setValidationErrors(errors);
    return !Object.values(errors).some(error => error !== '');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!validateForm()) {
      return;
    }

    setLoading(true);
    try {
      await resetPassword(phoneNumber, formData.otp, formData.newPassword);
      onSuccess();
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to reset password. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
      <Typography variant="h6" gutterBottom>
        Enter OTP and New Password
      </Typography>

      <TextField
        margin="normal"
        required
        fullWidth
        id="otp"
        label="OTP"
        name="otp"
        autoFocus
        value={formData.otp}
        onChange={handleChange}
        error={!!validationErrors.otp}
        helperText={validationErrors.otp}
        disabled={loading}
        inputProps={{
          pattern: "[0-9]*",
          maxLength: 6,
        }}
        placeholder="Enter 6-digit OTP"
      />

      <TextField
        margin="normal"
        required
        fullWidth
        name="newPassword"
        label="New Password"
        type="password"
        id="newPassword"
        value={formData.newPassword}
        onChange={handleChange}
        error={!!validationErrors.newPassword}
        helperText={validationErrors.newPassword}
        disabled={loading}
        placeholder="Minimum 8 characters"
      />

      <TextField
        margin="normal"
        required
        fullWidth
        name="confirmPassword"
        label="Confirm Password"
        type="password"
        id="confirmPassword"
        value={formData.confirmPassword}
        onChange={handleChange}
        error={!!validationErrors.confirmPassword}
        helperText={validationErrors.confirmPassword}
        disabled={loading}
        placeholder="Re-enter your password"
      />

      {error && (
        <Typography color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}

      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ 
          mt: 3, 
          mb: 2,
          bgcolor: 'primary.main',
          '&:hover': {
            bgcolor: 'primary.dark',
          },
          py: 1.5,
          borderRadius: 2
        }}
        disabled={loading || !formData.otp || !formData.newPassword || !formData.confirmPassword}
      >
        {loading ? <CircularProgress size={24} /> : 'Reset Password'}
      </Button>
    </Box>
  );
};

export default ResetPassword;