import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FolderIcon from '@mui/icons-material/Folder';

const GalleryFolder = ({ folder, onEdit, onDelete }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (event) => {
    if (event) event.stopPropagation();
    setAnchorEl(null);
  };

  const handleEditClick = (event) => {
    event.stopPropagation();
    handleMenuClose();
    onEdit(folder);
  };

  const handleDeleteClick = (event) => {
    event.stopPropagation();
    handleMenuClose();
    onDelete(folder);
  };

  const handleFolderClick = () => {
    navigate(`/chat-gallery/folders/${folder.id}`);
  };

  return (
    <Card 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        height: '100%',
        position: 'relative' 
      }}
    >
      <CardActionArea onClick={handleFolderClick}>
        {folder.coverImageUrl ? (
          <CardMedia
            component="img"
            height="160"
            image={folder.coverImageUrl}
            alt={folder.name}
          />
        ) : (
          <Box 
            sx={{ 
              height: 160, 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'center',
              backgroundColor: 'rgba(94, 53, 177, 0.1)' 
            }}
          >
            <FolderIcon sx={{ fontSize: 80, color: '#5E35B1' }} />
          </Box>
        )}
        <CardContent sx={{ flexGrow: 1 }}>
          <Typography gutterBottom variant="h6" component="div" noWrap>
            {folder.name}
          </Typography>
          {folder.description && (
            <Typography variant="body2" color="text.secondary" sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
            }}>
              {folder.description}
            </Typography>
          )}
        </CardContent>
      </CardActionArea>
      
      <Box sx={{ position: 'absolute', top: 8, right: 8 }}>
        <Tooltip title="Options">
          <IconButton 
            onClick={handleMenuOpen}
            sx={{ 
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
              }
            }}
            size="small"
          >
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleEditClick}>Edit</MenuItem>
          <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
        </Menu>
      </Box>
    </Card>
  );
};

export default GalleryFolder;