export const ROLES = {
  USER: 'USER',
  ADMIN: 'ADMIN',
  CONTENT_CREATOR: 'CONTENT_CREATOR',
  SCHOOL_CONTENT_CREATOR: 'SCHOOL_CONTENT_CREATOR',
  USER_MANAGER: 'USER_MANAGER',
  SCHOOL_EVENT_MANAGER: 'SCHOOL_EVENT_MANAGER',
  SMC_EVENT_MANAGER: 'SMC_EVENT_MANAGER',
  GROUP_CHAT_MANAGER: 'GROUP_CHAT_MANAGER'
};

export const MENU_ACCESS = {
  '/': { // Dashboard
    roles: [ROLES.USER],
    text: 'Dashboard'
  },
  '/posts': {
    roles: [ROLES.ADMIN, ROLES.CONTENT_CREATOR, ROLES.SCHOOL_CONTENT_CREATOR],
    text: 'Posts'
  },
  '/users': {
    roles: [ROLES.ADMIN, ROLES.USER_MANAGER],
    text: 'Users'
  },
  '/highlights': {
    roles: [ROLES.ADMIN, ROLES.CONTENT_CREATOR, ROLES.SCHOOL_CONTENT_CREATOR],
    text: 'Highlights'
  },
  '/smc-events': {
    roles: [ROLES.ADMIN, ROLES.SMC_EVENT_MANAGER],
    text: 'SMC Events'
  },
  '/school-events': {
    roles: [ROLES.ADMIN, ROLES.SCHOOL_EVENT_MANAGER],
    text: 'School Events'
  },
  '/polls': {
    roles: [ROLES.ADMIN, ROLES.CONTENT_CREATOR],
    text: 'Polls'
  },
  '/participation': {
    roles: [ROLES.ADMIN, ROLES.USER_MANAGER],
    text: 'Participation'
  },
  '/chat-admin': {
    roles: [ROLES.ADMIN, ROLES.GROUP_CHAT_MANAGER],
    text: 'Chat Admin'
  },
  '/gos-circulars': {
    roles: [ROLES.ADMIN, ROLES.CONTENT_CREATOR],
    text: 'GOs & Circulars'
  },
  '/sed-initiatives': {
    roles: [ROLES.ADMIN, ROLES.CONTENT_CREATOR],
    text: 'SED Initiatives'
  },
  '/reported-posts': {
    roles: [ROLES.ADMIN],
    text: 'Reported Posts'
  }
};

// Helper functions for role checking
export const hasRole = (user, role) => {
  return user?.roles?.includes(role) || false;
};

export const hasAnyRole = (user, roles) => {
  return user?.roles?.some(role => roles.includes(role)) || false;
};

export const canAccessPath = (user, path) => {
  // Admin can access everything
  if (hasRole(user, ROLES.ADMIN)) return true;

  // Check if path exists in MENU_ACCESS
  const menuItem = MENU_ACCESS[path];
  if (!menuItem) {
    // For sub-routes, check the parent route
    const parentPath = '/' + path.split('/')[1];
    const parentMenuItem = MENU_ACCESS[parentPath];
    return parentMenuItem ? hasAnyRole(user, parentMenuItem.roles) : false;
  }

  return hasAnyRole(user, menuItem.roles);
};

// Get accessible menu items for the user
export const getAccessibleMenuItems = (user) => {
  if (!user) return [];

  return Object.entries(MENU_ACCESS)
    .filter(([path, config]) => hasAnyRole(user, config.roles))
    .map(([path, config]) => ({
      path,
      text: config.text
    }));
};