import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
  CircularProgress,
  Alert
} from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const FolderDialog = ({ open, onClose, folder, galleryId, onSave, title }) => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    galleryId: galleryId
  });
  const [coverImage, setCoverImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');

  // Initialize form data when folder prop changes
  useEffect(() => {
    if (folder) {
      setFormData({
        name: folder.name || '',
        description: folder.description || '',
        galleryId: folder.galleryId || galleryId
      });
    } else {
      setFormData({
        name: '',
        description: '',
        galleryId: galleryId
      });
    }
  }, [folder, galleryId]);

  // Clean up preview URL when component unmounts
  useEffect(() => {
    return () => {
      if (imagePreview) {
        URL.revokeObjectURL(imagePreview);
      }
    };
  }, [imagePreview]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('image/')) {
      setCoverImage(file);
      
      // Create preview
      const previewUrl = URL.createObjectURL(file);
      setImagePreview(previewUrl);
      setError('');
    } else if (file) {
      setError('Please select a valid image file');
    }
  };

  const resetForm = () => {
    setFormData({
      name: '',
      description: '',
      galleryId: galleryId
    });
    setCoverImage(null);
    setImagePreview(null);
    setError('');
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const handleSubmit = async () => {
    // Validate form
    if (!formData.name.trim()) {
      setError('Please enter a folder name');
      return;
    }

    setIsSubmitting(true);
    setError('');

    try {
      // Pass data to parent component to handle save operation
      await onSave(formData, coverImage, folder ? folder.id : null);
      handleClose();
    } catch (error) {
      console.error('Failed to save folder:', error);
      setError('Failed to save folder. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>{title || (folder ? 'Edit Folder' : 'Create New Folder')}</DialogTitle>
      <DialogContent>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <TextField
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          required
          error={!formData.name.trim()}
          helperText={!formData.name.trim() ? 'Folder name is required' : ''}
        />

        <TextField
          label="Description"
          name="description"
          value={formData.description}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          multiline
          rows={3}
        />

        {/* Cover Image Upload Section */}
        <Box sx={{ mt: 2, mb: 2 }}>
          <Typography variant="subtitle1" gutterBottom>
            Cover Image (Optional)
          </Typography>
          
          {imagePreview ? (
            <Box sx={{ mb: 2, textAlign: 'center' }}>
              <img
                src={imagePreview}
                alt="Cover Preview"
                style={{ maxWidth: '100%', maxHeight: '200px', objectFit: 'contain' }}
              />
            </Box>
          ) : folder && folder.coverImageUrl ? (
            <Box sx={{ mb: 2, textAlign: 'center' }}>
              <img
                src={folder.coverImageUrl}
                alt="Current Cover"
                style={{ maxWidth: '100%', maxHeight: '200px', objectFit: 'contain' }}
              />
              <Typography variant="caption" display="block">
                Current cover image (select a new one to replace)
              </Typography>
            </Box>
          ) : (
            <Box
              sx={{
                p: 3,
                border: '2px dashed #ccc',
                borderRadius: 1,
                textAlign: 'center',
                cursor: 'pointer',
                '&:hover': { borderColor: '#5E35B1' }
              }}
              onClick={() => document.getElementById('cover-image-input').click()}
            >
              <FolderIcon sx={{ fontSize: 60, color: '#5E35B1' }} />
              <Typography variant="body1" sx={{ mt: 1 }}>
                Click to select a cover image
              </Typography>
            </Box>
          )}
          
          <input
            type="file"
            id="cover-image-input"
            onChange={handleImageChange}
            style={{ display: 'none' }}
            accept="image/*"
          />
          
          <Button
            variant="outlined"
            onClick={() => document.getElementById('cover-image-input').click()}
            startIcon={<CloudUploadIcon />}
            fullWidth
            sx={{ mt: 1 }}
          >
            Select Cover Image
          </Button>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={isSubmitting || !formData.name.trim()}
          startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
        >
          {isSubmitting ? 'Saving...' : (folder ? 'Update' : 'Create')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FolderDialog;