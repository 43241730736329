import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import UserSearch from '../search/UserSearch';

const UserListHeader = ({
  searchTerm,
  onSearchChange,
  onClearSearch,
  searchLoading,
  onCreateUser
}) => {
  return (
    <>
      <Typography variant="h4" align="center" gutterBottom>
        Users
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <UserSearch
          searchTerm={searchTerm}
          onSearchChange={onSearchChange}
          onClearSearch={onClearSearch}
          loading={searchLoading}
        />
        
        <Button
          variant="contained"
          color="primary"
          onClick={onCreateUser}
        >
          Create New User
        </Button>
      </Box>
    </>
  );
};

export default UserListHeader;