import { useState, useEffect } from 'react';
import { listUsers, deleteUser } from '../services/userService';
import { api } from '../services/api';
import _ from 'lodash';

export const useUserManagement = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchLoading, setSearchLoading] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalElements, setTotalElements] = useState(0);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const data = await listUsers(page, rowsPerPage);
      setUsers(data.content || []);
      setTotalElements(data.totalElements || 0);
    } catch (err) {
      setError('Failed to fetch users. Please try again.');
      console.error('Fetch users error:', err);
    } finally {
      setLoading(false);
    }
  };

  const searchUserByPhone = async (phoneNumber) => {
    try {
      setSearchLoading(true);
      setError('');
      const response = await api.get(`/aluminiapp/v2/profile/byphonenumber/${phoneNumber}`);
      if (response.data) {
        if (!users.some(u => u.id === response.data.id)) {
          setUsers([response.data, ...users]);
          setTotalElements(prev => prev + 1);
        }
        setSuccessMessage('User found successfully!');
        setTimeout(() => setSuccessMessage(''), 3000);
      }
    } catch (err) {
      const errorMessage = err.response?.data?.message || 'User not found';
      setError(errorMessage);
    } finally {
      setSearchLoading(false);
    }
  };

  const handleDeleteUser = async (userId) => {
    try {
      await deleteUser(userId);
      await fetchUsers();
      setSuccessMessage('User deleted successfully');
      setTimeout(() => setSuccessMessage(''), 3000);
    } catch (err) {
      setError('Failed to delete user. Please try again.');
      console.error('Delete user error:', err);
    }
  };

  const handleRolesUpdate = (userId, newRoles) => {
    setUsers(users.map(user => {
      if (user.id === userId) {
        return { ...user, roles: newRoles };
      }
      return user;
    }));
    setSuccessMessage('Roles updated successfully');
    setTimeout(() => setSuccessMessage(''), 3000);
  };

  const debouncedPhoneSearch = _.debounce(searchUserByPhone, 500);

  useEffect(() => {
    fetchUsers();
  }, [page, rowsPerPage]);

  return {
    users,
    loading,
    searchLoading,
    error,
    successMessage,
    page,
    rowsPerPage,
    totalElements,
    setPage,
    setRowsPerPage,
    setError,
    searchUserByPhone: debouncedPhoneSearch,
    handleDeleteUser,
    handleRolesUpdate,
    fetchUsers
  };
};