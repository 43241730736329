import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { highlightService } from "../services/highlightService";
import { useAuth } from "../contexts/AuthContext";
import ContentBlock from "../components/ContentBlocks/ContentBlock";
import { cleanPayload } from "../utils/payloadCleaner";

import {
  Box,
  Button,
  Container,
  Typography,
  TextField,
} from "@mui/material";

const EditHighlight = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [files, setFiles] = useState({});
  const [highlightData, setHighlightData] = useState({
    userId: user.userId,
    contentBlocks: [],
    contentType: "HIGHLIGHT",
    targetAudience: `STATE#Karnataka`,
    visibilityLevel: "STATE",
    optionsByContentType: "HIGHLIGHT",
    expiryDate: null,
  });

  useEffect(() => {
    const fetchHighlight = async () => {
      try {
        const fetchedHighlight = await highlightService.getHighlightById(id);
        setHighlightData((prev) => ({
          ...prev,
          ...fetchedHighlight,
          contentBlocks: fetchedHighlight.contentBlocks || [],
          expiryDate: fetchedHighlight.expiryDate ? new Date(fetchedHighlight.expiryDate).toISOString().slice(0, 16) : null,
        }));
      } catch (err) {
        setError("Failed to fetch highlight");
        console.error("Fetch highlight error:", err);
      }
    };

    if (id) {
      fetchHighlight();
    }
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setHighlightData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (blockIdentifier, newFiles) => {
    console.log('EditHighlight handleFileChange received:', { blockIdentifier, newFiles });
    
    if (!newFiles || !Array.isArray(newFiles)) {
      console.error('Invalid files received:', newFiles);
      return;
    }

    setFiles(prevFiles => {
      const existingBlockFiles = prevFiles[blockIdentifier] || [];
      
      // Filter out any files with the same fileType as the new files
      const newFileTypes = newFiles.map(f => f.fileType);
      const filteredExisting = existingBlockFiles.filter(f => !newFileTypes.includes(f.fileType));
      
      return {
        ...prevFiles,
        [blockIdentifier]: [...filteredExisting, ...newFiles]
      };
    });

    // Debug log the files state after update
    setFiles(current => {
      console.log('Files state after update:', current);
      return current;
    });
  };

  const updateContentBlock = (index, updatedBlock) => {
    setHighlightData((prev) => {
      const newBlocks = [...prev.contentBlocks];
      newBlocks[index] = updatedBlock;
      return { ...prev, contentBlocks: newBlocks };
    });
  };

  const removeContentBlock = (index) => {
    setHighlightData((prev) => ({
      ...prev,
      contentBlocks: prev.contentBlocks.filter((_, i) => i !== index),
    }));
  };

  const moveContentBlock = (fromIndex, toIndex) => {
    if (toIndex < 0 || toIndex >= highlightData.contentBlocks.length) return;
    setHighlightData((prev) => {
      const newBlocks = [...prev.contentBlocks];
      const [movedBlock] = newBlocks.splice(fromIndex, 1);
      newBlocks.splice(toIndex, 0, movedBlock);
      return { ...prev, contentBlocks: newBlocks };
    });
  };

  const addNewContentBlock = () => {
    setHighlightData((prev) => ({
      ...prev,
      contentBlocks: [
        ...prev.contentBlocks,
        {
          type: "Image",
          order: prev.contentBlocks.length + 1,
          imageContent: { url: "", caption: "" },
          createdDate: new Date().toISOString(),
          updatedDate: new Date().toISOString(),
          optionsByContentType: highlightData.optionsByContentType,
        },
      ],
    }));
  };

  const validateVideoBlocks = () => {
    const errors = [];
    highlightData.contentBlocks.forEach((block, index) => {
      if (block.type === 'Video') {
        const blockFiles = files[block.order.toString()] || [];
        const hasVideoFile = blockFiles.some(file => file.fileType === 'video');
        const hasThumbnailFile = blockFiles.some(file => file.fileType === 'thumbnail');
        
        if (!hasVideoFile && !block.videoContent?.url?.trim()) {
          errors.push(`Content Block ${index + 1}: Please either upload a video or provide a video URL`);
        }
      }
    });
    return errors;
  };

  const hasValidationErrors = highlightData.contentBlocks.some(block => block.hasValidationError);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    if (highlightData.contentBlocks.length === 0) {
      setError("At least one content block is required.");
      return;
    }

    if (!highlightData.name?.trim()) {
      setError("Highlight name is required.");
      return;
    }

    // Validate video thumbnails
    const validationErrors = validateVideoBlocks();
    if (validationErrors.length > 0) {
      setError(validationErrors.join('\n'));
      return;
    }

    // Check for validation errors in blocks
    if (hasValidationErrors) {
      setError("Please fix validation errors in content blocks before submitting");
      return;
    }

    const formData = new FormData();

    // Clean the payload and prepare submission data
    const submissionData = cleanPayload({
      ...highlightData,
      visibilityLevel: 'STATE',
      targetAudience: `STATE#Karnataka`,
      expiryDate: highlightData.expiryDate ? new Date(highlightData.expiryDate).toISOString() : null,
    });

    formData.append("metadata", JSON.stringify(submissionData));

    Object.entries(files).forEach(([blockIdentifier, blockFiles]) => {
      if (!Array.isArray(blockFiles)) {
        console.error(`Invalid files array for block ${blockIdentifier}:`, blockFiles);
        return;
      }

      blockFiles.forEach((file, index) => {
        if (!(file instanceof File)) {
          console.error(`Invalid file object in block ${blockIdentifier}:`, file);
          return;
        }

        const fileType = file.fileType || (file.type.startsWith('image/') ? 'image' : 'unknown');
        const fileName = `file_${blockIdentifier}_${fileType}_${index}_${file.name}`;
        formData.append('file', file, fileName);
      });
    });

    // Log the final FormData contents for debugging
    console.log('FormData contents:');
    for (let [key, value] of formData.entries()) {
      console.log(key, value instanceof File ? `File: ${value.name}` : value);
    }

    try {
      await highlightService.updateHighlight(id, formData);
      navigate("/highlights");
    } catch (err) {
      setError("Failed to update highlight. Please try again.");
      console.error("Update highlight error:", err);
    }
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" align="center" sx={{ mt: 4, mb: 2 }}>
        Edit Highlight
      </Typography>
      {error && (
        <Typography color="error" align="center" sx={{ mb: 2, whiteSpace: 'pre-line' }}>
          {error}
        </Typography>
      )}
      <form onSubmit={handleSubmit}>
        <Box sx={{ mb: 2 }}>
          <TextField
            fullWidth
            label="Highlight Name"
            name="name"
            value={highlightData.name || ''}
            onChange={handleInputChange}
            required
            placeholder="Enter a name for this highlight"
            sx={{ mb: 2 }}
          />
          {/* Content Type is hardcoded to HIGHLIGHT and not shown to users */}
        </Box>
        <Box sx={{ mb: 2 }}>
          <TextField
            fullWidth
            label="Expiry Date"
            type="datetime-local"
            name="expiryDate"
            value={highlightData.expiryDate || ''}
            onChange={handleInputChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
        <Box sx={{ mb: 2 }}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            Content Blocks
          </Typography>
          {highlightData.contentBlocks.map((block, index) => (
            <ContentBlock
              key={index}
              block={block}
              index={index}
              updateBlock={updateContentBlock}
              removeBlock={removeContentBlock}
              moveBlock={moveContentBlock}
              onFileChange={handleFileChange}
              optionsByContentType={"HIGHLIGHT"}
            />
          ))}
          <Button
            variant="outlined"
            onClick={addNewContentBlock}
            sx={{ mt: 2 }}
          >
            Add New Content Block
          </Button>
        </Box>
        <Button 
          type="submit" 
          variant="contained" 
          color="primary" 
          fullWidth
          disabled={
            highlightData.contentBlocks.length === 0 ||
            hasValidationErrors
          }
        >
          Update Highlight
        </Button>
      </form>
    </Container>
  );
};

export default EditHighlight;