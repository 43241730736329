import React, { useState } from 'react';
import { Container, Paper, Box, Typography, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import RequestOTP from '../components/auth/RequestOTP';
import ResetPassword from '../components/auth/ResetPassword';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [success, setSuccess] = useState(false);

  const handleOTPSent = () => {
    setStep(2);
  };

  const handlePasswordReset = () => {
    setSuccess(true);
    setTimeout(() => {
      navigate('/login');
    }, 3000);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Paper elevation={3} sx={{ p: 4, width: '100%' }}>
          <Typography component="h1" variant="h5" align="center" gutterBottom>
            Forgot Password
          </Typography>

          {success && (
            <Alert severity="success" sx={{ mb: 2 }}>
              Password reset successful! Redirecting to login...
            </Alert>
          )}

          {step === 1 && (
            <RequestOTP
              onSuccess={handleOTPSent}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
            />
          )}

          {step === 2 && (
            <ResetPassword
              phoneNumber={phoneNumber}
              onSuccess={handlePasswordReset}
            />
          )}
        </Paper>
      </Box>
    </Container>
  );
};

export default ForgotPassword;