// CarouselBlock.js
import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Stack,
  Alert
} from '@mui/material';
import { 
  Delete, 
  ArrowUpward, 
  ArrowDownward, 
  Image as ImageIcon,
  CheckCircle as CheckCircleIcon,
  UploadFile as UploadIcon
} from '@mui/icons-material';

const CarouselBlock = ({ block, updateBlock, onFileChange }) => {
  const [selectedFiles, setSelectedFiles] = useState({});

  const addItem = () => {
    const newItem = {
      id: `carousel-${Date.now()}`,
      type: 'image', // Always image type
      url: '',
      caption: '',
    };

    updateBlock({
      ...block,
      carouselContent: {
        items: [...(block.carouselContent?.items || []), newItem],
      },
    });
  };

  const updateItem = (index, updatedItem) => {
    const updatedItems = [...(block.carouselContent?.items || [])];
    updatedItems[index] = updatedItem;
    updateBlock({
      ...block,
      carouselContent: {
        items: updatedItems,
      },
    });
  };

  const removeItem = (index) => {
    const updatedItems = [...(block.carouselContent?.items || [])];
    updatedItems.splice(index, 1);
    setSelectedFiles((prev) => {
      const updated = { ...prev };
      delete updated[index];
      return updated;
    });
    updateBlock({
      ...block,
      carouselContent: {
        items: updatedItems,
      },
    });
  };

  const moveItem = (index, direction) => {
    const newIndex = index + direction;
    if (newIndex < 0 || newIndex >= (block.carouselContent?.items?.length || 0)) return;
    
    const updatedItems = [...(block.carouselContent?.items || [])];
    [updatedItems[index], updatedItems[newIndex]] = [updatedItems[newIndex], updatedItems[index]];
    
    // Update selected files state
    const updatedSelectedFiles = {};
    Object.entries(selectedFiles).forEach(([key, value]) => {
      if (parseInt(key) === index) {
        updatedSelectedFiles[newIndex] = value;
      } else if (parseInt(key) === newIndex) {
        updatedSelectedFiles[index] = value;
      } else {
        updatedSelectedFiles[key] = value;
      }
    });
    setSelectedFiles(updatedSelectedFiles);

    updateBlock({
      ...block,
      carouselContent: {
        items: updatedItems,
      },
    });
  };

  const handleFileChange = (e, index) => {
    const file = e.target.files[0];
    if (file) {
      // Create a new file object with fileType property
      const fileWithType = new File([file], file.name, { type: file.type });
      fileWithType.fileType = 'image'; // Always set as image
      
      onFileChange([fileWithType]); // Pass the modified file object
      setSelectedFiles(prev => ({
        ...prev,
        [index]: file.name
      }));

      const updatedItems = [...(block.carouselContent?.items || [])];
      updatedItems[index] = {
        ...updatedItems[index],
        url: '', // Will be set by server after upload
        type: 'image', // Always set as image
      };
      updateBlock({
        ...block,
        carouselContent: {
          items: updatedItems,
        },
      });
    }
  };

  return (
    <Box mt={2}>
      <Typography variant="subtitle1" color="textSecondary" gutterBottom>
        Add images to create a slideshow. Each image can have a caption.
      </Typography>

      <Stack spacing={2}>
        {block.carouselContent?.items?.map((item, index) => (
          <Paper 
            key={item.id || index} 
            sx={{ 
              p: 3,
              backgroundColor: '#fafafa',
              position: 'relative',
              '&:hover': {
                backgroundColor: '#f5f5f5'
              }
            }}
          >
            <Box 
              sx={{ 
                position: 'absolute',
                top: -12,
                left: 16,
                backgroundColor: 'primary.main',
                color: 'white',
                px: 2,
                py: 0.5,
                borderRadius: 1,
                fontSize: '0.875rem'
              }}
            >
              Image {index + 1}
            </Box>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  component="label"
                  fullWidth
                  sx={{ 
                    height: '56px',
                    borderStyle: 'dashed',
                    display: 'flex',
                    gap: 1
                  }}
                >
                  {selectedFiles[index] ? (
                    <>
                      <CheckCircleIcon color="success" />
                      {selectedFiles[index]}
                    </>
                  ) : (
                    <>
                      <UploadIcon />
                      Upload Image
                    </>
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    hidden
                    onChange={(e) => handleFileChange(e, index)}
                  />
                </Button>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Caption"
                  value={item.caption}
                  onChange={(e) =>
                    updateItem(index, { ...item, caption: e.target.value })
                  }
                  fullWidth
                  multiline
                  rows={2}
                  placeholder="Add a caption for this image..."
                />
              </Grid>

              <Grid item xs={12}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                  <Box>
                    <Tooltip title="Move Up">
                      <IconButton 
                        onClick={() => moveItem(index, -1)}
                        disabled={index === 0}
                        size="small"
                      >
                        <ArrowUpward />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Move Down">
                      <IconButton 
                        onClick={() => moveItem(index, 1)}
                        disabled={index === (block.carouselContent?.items?.length || 0) - 1}
                        size="small"
                      >
                        <ArrowDownward />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Tooltip title="Remove Image">
                    <IconButton 
                      onClick={() => removeItem(index)}
                      color="error"
                      size="small"
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        ))}
      </Stack>

      {(!block.carouselContent?.items || block.carouselContent.items.length === 0) && (
        <Alert severity="info" sx={{ mb: 2 }}>
          Add your first image to create a carousel slideshow
        </Alert>
      )}

      <Button 
        variant="contained" 
        onClick={addItem}
        startIcon={<ImageIcon />}
        sx={{ mt: 2 }}
      >
        Add New Image
      </Button>
    </Box>
  );
};

export default CarouselBlock;