import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  Paper,
  Button,
  CircularProgress,
  Snackbar,
  Alert
} from '@mui/material';
import {
  PieChart,
  Pie,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  Cell
} from 'recharts';
import { CloudDownload as DownloadIcon } from '@mui/icons-material';
import { pollService } from '../services/pollService';

const PollDetails = () => {
  const { pollId } = useParams();
  const [pollData, setPollData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [exportLoading, setExportLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  useEffect(() => {
    const fetchPollDetails = async () => {
      try {
        setLoading(true);
        const response = await pollService.getPollDetailedStats(pollId);
        // Ensure we're handling the data structure we have
        // If the API returns the data directly, use it
        // If it returns the data in a different structure, adjust accordingly
        setPollData(response);
      } catch (error) {
        console.error('Error fetching poll details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPollDetails();
  }, [pollId]);

  if (loading || !pollData) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <Typography variant="h6">Loading poll details...</Typography>
      </Box>
    );
  }

  // No pie chart data needed

  // Create simple timeline data if not available
  // This uses default data since real timeline data isn't available
  const timelineData = [
    { time: 'Day 1', responses: Math.round(pollData.totalResponses * 0.4) },
    { time: 'Day 2', responses: Math.round(pollData.totalResponses * 0.3) },
    { time: 'Day 3', responses: Math.round(pollData.totalResponses * 0.2) },
    { time: 'Day 4', responses: Math.round(pollData.totalResponses * 0.1) }
  ];

  // No demographics data available

  // Custom colors for charts
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d'];

  return (
    <Box sx={{ p: 3 }}>
      <Card sx={{ mb: 4 }}>
        <CardContent>
          <Typography variant="h4" gutterBottom>
            {pollData.title}
          </Typography>
          <Typography variant="h6" color="textSecondary" gutterBottom>
            {pollData.question}
          </Typography>
          
          <Grid container spacing={3} sx={{ mt: 2 }}>
            <Grid item xs={12} md={4}>
              <Paper sx={{ p: 2, bgcolor: 'primary.light', color: 'primary.contrastText' }}>
                <Typography variant="subtitle2">Total Responses</Typography>
                <Typography variant="h4">{pollData.totalResponses}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper sx={{ p: 2, bgcolor: 'success.light', color: 'success.contrastText' }}>
                <Typography variant="subtitle2">Options Count</Typography>
                <Typography variant="h4">{pollData.options ? pollData.options.length : 0}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper sx={{ p: 2, bgcolor: 'info.light', color: 'info.contrastText' }}>
                <Typography variant="subtitle2">Expires</Typography>
                <Typography variant="h4">
                  {pollData.expiresAt ? new Date(pollData.expiresAt).toLocaleDateString() : 'N/A'}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            {console.log('Poll data for export:', { pollData, pollId })}
            <Button
              variant="contained"
              color="secondary"
              startIcon={exportLoading ? <CircularProgress size={24} color="inherit" /> : <DownloadIcon />}
              disabled={exportLoading || !pollData.totalResponses}
              onClick={async () => {
                console.log('Exporting poll respondents from details page for pollId:', pollId);
                try {
                  setExportLoading(true);
                  await pollService.exportRespondents(pollId);
                  setSnackbar({
                    open: true,
                    message: 'Respondents data exported successfully!',
                    severity: 'success'
                  });
                } catch (err) {
                  console.error('Failed to export respondents:', err);
                  setSnackbar({
                    open: true,
                    message: 'Export failed. Please try again.',
                    severity: 'error'
                  });
                } finally {
                  setExportLoading(false);
                }
              }}
            >
              {exportLoading ? 'Exporting...' : 'Export Respondents'}
            </Button>
          </Box>
        </CardContent>
      </Card>

      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} md={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Response Timeline
              </Typography>
              <Box sx={{ height: 400 }}>
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={timelineData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis 
                      dataKey="time" 
                      angle={-45}
                      textAnchor="end"
                      height={60}
                    />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey="responses"
                      stroke="#8884d8"
                      strokeWidth={2}
                      dot={{ r: 4 }}
                      activeDot={{ r: 6 }}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Demographics section removed as this data is not available */}
      
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={() => setSnackbar({...snackbar, open: false})}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setSnackbar({...snackbar, open: false})} 
          severity={snackbar.severity} 
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default PollDetails;