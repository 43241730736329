import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Box,
  Grid,
  Button,
  Pagination,
  CircularProgress,
  Breadcrumbs,
  Link,
  Divider,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import { galleryService } from '../../services/galleryService';
import { chatService } from '../../services/chatService';
import GalleryCard from '../../components/chat/GalleryCard';
import GalleryDialog from '../../components/chat/GalleryDialog';

const GalleryList = () => {
  const { groupId } = useParams();
  const navigate = useNavigate();
  
  const [galleries, setGalleries] = useState([]);
  const [chatGroup, setChatGroup] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [galleryDialogOpen, setGalleryDialogOpen] = useState(false);
  const [selectedGallery, setSelectedGallery] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [galleryToDelete, setGalleryToDelete] = useState(null);
  const [refreshKey, setRefreshKey] = useState(0);

  const pageSize = 12;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError('');
      try {
        // Fetch chat group details
        const groupResponse = await chatService.getGroup(groupId);
        setChatGroup(groupResponse.data);
        
        // Fetch galleries for this chat group
        const galleriesResponse = await galleryService.getGalleriesForChatGroup(
          groupId, 
          page - 1, 
          pageSize
        );
        
        console.log('Galleries response:', galleriesResponse);
        
        // Check if response has the expected structure
        if (galleriesResponse && galleriesResponse.data) {
          setGalleries(galleriesResponse.data.content || []);
          setTotalPages(galleriesResponse.data.totalPages || 1);
        } else {
          console.error('Unexpected response structure:', galleriesResponse);
          setError('Unexpected response format from server. Please try again.');
        }
      } catch (err) {
        console.error('Error fetching data:', err);
        if (err.response) {
          console.error('Error response:', err.response.data);
          setError(`Failed to load galleries: ${err.response.data.message || 'Server error'}`);
        } else if (err.request) {
          // The request was made but no response was received
          setError('Network error. Please check your connection.');
        } else {
          // Something else caused the error
          setError('Failed to load galleries. Please try again.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [groupId, page, refreshKey]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleAddGallery = () => {
    setSelectedGallery(null);
    setGalleryDialogOpen(true);
  };

  const handleEditGallery = (gallery) => {
    setSelectedGallery(gallery);
    setGalleryDialogOpen(true);
  };

  const handleDeletePrompt = (gallery) => {
    setGalleryToDelete(gallery);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!galleryToDelete) return;
    
    try {
      await galleryService.deleteGallery(galleryToDelete.id);
      setDeleteDialogOpen(false);
      setGalleryToDelete(null);
      // Refresh the gallery list
      setRefreshKey(prev => prev + 1);
    } catch (err) {
      console.error('Error deleting gallery:', err);
      setError('Failed to delete gallery. Please try again.');
    }
  };

  const handleSaveGallery = async (formData, coverImage, galleryId) => {
    setError(''); // Clear any existing errors
    try {
      if (galleryId) {
        // Update existing gallery
        const response = await galleryService.updateGallery(galleryId, formData, coverImage);
        console.log('Gallery update response:', response);
      } else {
        // Create new gallery
        const response = await galleryService.createGallery(formData, coverImage);
        console.log('Gallery creation response:', response);
      }
      // Refresh gallery list
      setRefreshKey(prev => prev + 1);
      return true;
    } catch (err) {
      console.error('Error saving gallery:', err);
      throw err;
    }
  };

  if (loading && !galleries.length) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      {/* Breadcrumb Navigation */}
      <Box mb={3}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
          <Link 
            color="inherit" 
            component="button"
            onClick={() => navigate('/chat-admin')}
            sx={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}
          >
            <ArrowBackIcon fontSize="small" sx={{ mr: 0.5 }} />
            Chat Groups
          </Link>
          <Typography color="text.primary">
            {chatGroup?.name || 'Gallery'}
          </Typography>
        </Breadcrumbs>
      </Box>
      
      {/* Header Section */}
      <Box 
        display="flex" 
        justifyContent="space-between" 
        alignItems="center"
        mb={3}
      >
        <Box display="flex" alignItems="center">
          <PhotoLibraryIcon fontSize="large" sx={{ mr: 2, color: '#5E35B1' }} />
          <Typography variant="h4">
            {chatGroup?.name} - Galleries
          </Typography>
        </Box>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddGallery}
        >
          Add Gallery
        </Button>
      </Box>
      
      <Divider sx={{ mb: 3 }} />
      
      {/* Error Message */}
      {error && (
        <Alert 
          severity="error" 
          sx={{ mb: 3 }}
          action={
            <Button 
              color="inherit" 
              size="small" 
              startIcon={<RefreshIcon />}
              onClick={() => setRefreshKey(prev => prev + 1)}
            >
              Retry
            </Button>
          }
        >
          {error}
        </Alert>
      )}
      
      {/* Galleries Grid */}
      {galleries.length > 0 ? (
        <Grid container spacing={3}>
          {galleries.map((gallery) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={gallery.id}>
              <GalleryCard 
                gallery={gallery} 
                onEdit={handleEditGallery}
                onDelete={handleDeletePrompt}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box 
          sx={{ 
            minHeight: '40vh', 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            justifyContent: 'center',
            backgroundColor: 'rgba(94, 53, 177, 0.05)',
            borderRadius: 2,
            p: 3
          }}
        >
          <PhotoLibraryIcon sx={{ fontSize: 80, color: '#5E35B1', opacity: 0.5, mb: 2 }} />
          <Typography variant="h6" color="text.secondary" gutterBottom>
            No galleries found
          </Typography>
          <Typography variant="body2" color="text.secondary" align="center" mb={3}>
            Create your first gallery for this chat group by clicking the "Add Gallery" button.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleAddGallery}
          >
            Add Gallery
          </Button>
        </Box>
      )}
      
      {/* Pagination */}
      {totalPages > 1 && (
        <Box display="flex" justifyContent="center" mt={4}>
          <Pagination 
            count={totalPages} 
            page={page}
            onChange={handlePageChange}
            color="primary"
          />
        </Box>
      )}
      
      {/* Gallery Dialog */}
      <GalleryDialog
        open={galleryDialogOpen}
        onClose={() => setGalleryDialogOpen(false)}
        gallery={selectedGallery}
        chatGroupId={groupId}
        onSave={handleSaveGallery}
        title={selectedGallery ? 'Edit Gallery' : 'Create New Gallery'}
      />
      
      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the gallery "{galleryToDelete?.name}"? 
            This will also delete all folders and media files within this gallery.
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default GalleryList;