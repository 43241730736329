import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { createPost } from "../services/postService";
import { useAuth } from "../contexts/AuthContext";
import ContentBlock from "../components/ContentBlocks/ContentBlock";
import CategorySelector from "../components/CategorySelector";

import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";

const CreatePost = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [files, setFiles] = useState({});
  const [thumbnailFile, setThumbnailFile] = useState(null);
  const [thumbnailPreview, setThumbnailPreview] = useState(null);
  const [postData, setPostData] = useState({
    userId: user.userId,
    authorId: user.userId,
    authorName: user.name,
    authorRole: user.role,
    schoolId: user.schoolId,
    title: "",
    summary: "",
    contentType: "ANNOUNCEMENT",
    ctaType: "",
    contentBlocks: [],
    categories: [],
    tags: [],
    targetAudience: `STATE#Karnataka`,
    visibilityLevel: "STATE",
    likeCount: 0,
    commentCount: 0,
    shareCount: 0,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPostData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCategoryChange = (newCategories) => {
    setPostData(prev => ({
      ...prev,
      categories: newCategories
    }));
  };


  const handleThumbnailChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      setThumbnailFile(file);
      
      // Create a preview URL for the selected file
      const previewUrl = URL.createObjectURL(file);
      setThumbnailPreview(previewUrl);
    } else {
      setError('Please select a valid image file for thumbnail');
    }
  };

  // Clean up the created object URL when component unmounts
  useEffect(() => {
    return () => {
      if (thumbnailPreview) {
        URL.revokeObjectURL(thumbnailPreview);
      }
    };
  }, [thumbnailPreview]);

  const handleFileChange = (blockIdentifier, newFiles) => {
    console.log('=== Debug File Change ===');
    console.log('Block identifier:', blockIdentifier);
    console.log('New files:', newFiles);
    console.log('Current block:', postData.contentBlocks.find(block => block.order.toString() === blockIdentifier.toString()));
    console.log('Current files state:', files);

    if (!newFiles || !Array.isArray(newFiles)) {
      console.error('Invalid files received:', newFiles);
      return;
    }

    if (newFiles.length === 0) {
      console.error('Empty files array received');
      return;
    }

    console.log('File details:', newFiles.map(file => ({
      name: file.name,
      type: file.type,
      fileType: file.fileType,
      size: file.size
    })));

    const blockKey = blockIdentifier.toString();
    const contentBlock = postData.contentBlocks.find(block => block.order.toString() === blockKey);
    
    if (!contentBlock) {
      console.error('No matching content block found:', blockKey);
      return;
    }

    setFiles(prevFiles => {
      const processedNewFiles = newFiles.map(file => {
        // If file already has fileType, preserve it
        if (file.fileType) return file;

        const newFile = file instanceof File ? file : new File([file], file.name, { type: file.type });
        
        // Set fileType based on block type and file mime type
        switch(contentBlock.type) {
          case 'Image':
            newFile.fileType = 'image';
            break;
          case 'Video':
            newFile.fileType = 'video';
            break;
          case 'Carousel':
            newFile.fileType = file.type.startsWith('image/') ? 'image' : 'video';
            break;
          case 'File':
            newFile.fileType = 'file';
            break;
          default:
            newFile.fileType = file.type.startsWith('image/') ? 'image' : 
                              file.type.startsWith('video/') ? 'video' : 'file';
        }

        console.log('Processed file:', {
          name: newFile.name,
          type: newFile.type,
          fileType: newFile.fileType,
          blockType: contentBlock.type
        });

        return newFile;
      });

      const newState = {
        ...prevFiles,
        [blockKey]: contentBlock.type === 'Video' ?
          // For Video blocks, merge with existing files but filter duplicates by fileType
          [
            ...(prevFiles[blockKey] || []).filter(existingFile =>
              !processedNewFiles.some(newFile => newFile.fileType === existingFile.fileType)
            ),
            ...processedNewFiles
          ] :
          contentBlock.type === 'Carousel' ? 
            [...(prevFiles[blockKey] || []), ...processedNewFiles] : // Append for Carousel
            processedNewFiles // Replace for other types
      };

      console.log('Updated files state:', newState);
      return newState;
    });
  };


  const updateContentBlock = (index, updatedBlock) => {
    setPostData((prev) => {
      const newBlocks = [...prev.contentBlocks];
      newBlocks[index] = updatedBlock;
      return { ...prev, contentBlocks: newBlocks };
    });
  };

  const removeContentBlock = (index) => {
    setPostData((prev) => ({
      ...prev,
      contentBlocks: prev.contentBlocks.filter((_, i) => i !== index),
    }));
  };

  const moveContentBlock = (fromIndex, toIndex) => {
    if (toIndex < 0 || toIndex >= postData.contentBlocks.length) return;
    setPostData((prev) => {
      const newBlocks = [...prev.contentBlocks];
      const [movedBlock] = newBlocks.splice(fromIndex, 1);
      newBlocks.splice(toIndex, 0, movedBlock);
      return { ...prev, contentBlocks: newBlocks };
    });
  };

  const addNewContentBlock = () => {
    setPostData((prev) => ({
      ...prev,
      contentBlocks: [
        ...prev.contentBlocks,
        {
          type: "Text",
          order: prev.contentBlocks.length + 1,
          textContent: { text: "" },
          createdDate: new Date().toISOString(),
          updatedDate: new Date().toISOString(),
        },
      ],
    }));
  };

  const validateVideoBlocks = () => {
    const errors = [];
    postData.contentBlocks.forEach((block, index) => {
      if (block.type === 'Video') {
        // if (!block.videoContent?.thumbnailUrl?.trim()) {
        //   errors.push(`Content Block ${index + 1}: Thumbnail URL is required for video content`);
        // } else if (!block.videoContent.thumbnailUrl.match(/^https?:\/\/.+/)) {
        //   errors.push(`Content Block ${index + 1}: Invalid thumbnail URL format`);
        // }
      }
    });
    return errors;
  };

  const validateContentBlocks = () => {
    const errors = [];
    console.log('=== Debug Validation ===');
  console.log('Current files state:', files);
  console.log('Current blocks:', postData.contentBlocks);

    if (!postData.contentBlocks || postData.contentBlocks.length === 0) {
      errors.push("At least one content block is required");
      return errors;
    }

    postData.contentBlocks.forEach((block, index) => {
      const blockFiles = files[block.order.toString()] || [];
      const blockOrder = index + 1;

      switch (block.type) {
        case 'Text':
          if (!block.textContent?.text?.trim()) {
            errors.push(`Content Block ${blockOrder}: Text content cannot be empty`);
          }
          break;

        case 'Image':
          const hasImageFile = blockFiles.some(file => file.fileType === 'image');
          const hasImageUrl = block.imageContent?.url?.trim();
          if (!hasImageFile && !hasImageUrl) {
            errors.push(`Content Block ${blockOrder}: Please either upload an image or provide an image URL`);
          }
          break;

          case 'Video':
            const hasVideoFile = blockFiles.some(file => file.fileType === 'video');
            const hasVideoUrl = block.videoContent?.url?.trim();
            const hasThumbnailFile = blockFiles.some(file => file.fileType === 'thumbnail');
            const hasThumbnailUrl = block.videoContent?.thumbnailUrl?.trim();
          
            // Log video block validation details
            console.log('Video Block Validation:', {
            blockOrder,
              hasVideoFile,
            hasVideoUrl,
              hasThumbnailFile,
              hasThumbnailUrl,
            blockFiles
            });

          // Check video content
          if (!hasVideoFile && !hasVideoUrl) {
            errors.push(`Content Block ${blockOrder}: Please upload a video file`);
          }

          // Check thumbnail
          if (!hasThumbnailFile && !hasThumbnailUrl) {
            errors.push(`Content Block ${blockOrder}: Please upload a thumbnail`);
          }
            break;
            
        case 'Carousel':
          if (!block.carouselContent?.items?.length) {
            errors.push(`Content Block ${blockOrder}: Please add at least one item to the carousel`);
          } else {
            // Each carousel item must have either a file or URL
            block.carouselContent.items.forEach((item, itemIndex) => {
              const hasFile = blockFiles.some(file => file.fileType === item.type);
              const hasUrl = item.url?.trim();
              if (!hasFile && !hasUrl) {
                errors.push(`Content Block ${blockOrder}: Carousel item ${itemIndex + 1} needs either a file or URL`);
              }
            });
          }
          break;

        case 'File':
          const hasFile = blockFiles.some(file => file.fileType === 'file');
          const hasFileUrl = block.fileContent?.url?.trim();
          if (!hasFile && !hasFileUrl) {
            errors.push(`Content Block ${blockOrder}: Please either upload a file or provide a file URL`);
          }
          break;
      }
    });

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setIsSubmitting(true);

    try {
      // Basic field validation
      if (!postData.title.trim()) {
        throw new Error("Title is required");
      }

      if (!postData.summary.trim()) {
        throw new Error("Summary is required");
      }

      if (!postData.contentType) {
        throw new Error("Content Type is required");
      }

      // Validate content blocks and video thumbnails
      const blockErrors = validateContentBlocks();
      const videoErrors = validateVideoBlocks();
      const allErrors = [...blockErrors, ...videoErrors];

      if (allErrors.length > 0) {
        throw new Error(allErrors.join('\n'));
      }

      let submissionData = { ...postData };

      // Clean up categories and tags
      submissionData.categories = submissionData.categories || [];
      submissionData.tags = submissionData.tags || [];

      const formData = new FormData();
      if (thumbnailFile) {
        formData.append('thumbnail', thumbnailFile);
      }
      formData.append("metadata", JSON.stringify(submissionData));

      // Handle file uploads with improved error handling and naming
      // Helper function to get file extension
      const getFileExtension = (filename) => {
        return filename.substring(filename.lastIndexOf('.'));
      };

      Object.entries(files).forEach(([blockIdentifier, blockFiles]) => {
        if (!Array.isArray(blockFiles)) {
          console.error(`Invalid files array for block ${blockIdentifier}:`, blockFiles);
          return;
        }

        const contentBlock = postData.contentBlocks.find(block => 
          block.order.toString() === blockIdentifier.toString()
        );

        if (!contentBlock) {
          console.error(`No matching content block found for identifier ${blockIdentifier}`);
          return;
        }

        blockFiles.forEach((file, index) => {
          if (!(file instanceof File)) {
            console.error(`Invalid file object in block ${blockIdentifier}:`, file);
            return;
          }

          // Create a unique filename that includes block order and file type
          const fileType = file.fileType || 'unknown';
          const fileName = `file_${contentBlock.order}_${fileType}_${index}${getFileExtension(file.name)}`;
          formData.append('file', file, fileName);
        });
      });

      // Log the final FormData contents for debugging
      console.log('FormData contents:');
      for (let [key, value] of formData.entries()) {
        console.log(key, value instanceof File ? `File: ${value.name} (${value.type})` : value);
      }

      // Debug: Additional logging for thumbnail file
      if (thumbnailFile) {
        console.log('Thumbnail details:', {
          name: thumbnailFile.name,
          type: thumbnailFile.type,
          size: thumbnailFile.size,
          lastModified: new Date(thumbnailFile.lastModified).toISOString()
        });
      } else {
        console.log('No thumbnail file selected');
      }


      await createPost(formData);
      navigate("/");
    } catch (err) {
      setError(err.message || "Failed to create post. Please try again.");
      console.error("Create post error:", err);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" align="center" sx={{ mt: 4, mb: 2 }}>
        Create New Post
      </Typography>
      {error && (
        <Typography color="error" align="center" sx={{ mb: 2, whiteSpace: 'pre-line' }}>
          {error}
        </Typography>
      )}
      <form onSubmit={handleSubmit} noValidate>
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle1" sx={{ mb: 1 }}>Post Thumbnail</Typography>
          {thumbnailPreview && (
            <Box sx={{ mb: 1 }}>
              <img 
                src={thumbnailPreview} 
                alt="Thumbnail preview" 
                style={{ maxWidth: '200px', marginBottom: '8px', borderRadius: '4px' }} 
              />
            </Box>
          )}
          <input
            accept="image/*"
            type="file"
            onChange={handleThumbnailChange}
            style={{ display: 'none' }}
            id="thumbnail-upload"
          />
          <label htmlFor="thumbnail-upload">
            <Button
              variant="outlined"
              component="span"
              fullWidth
              sx={{ mb: 2 }}
            >
              {thumbnailFile ? thumbnailFile.name : 'Upload Thumbnail Image'}
            </Button>
          </label>
        </Box>
        <Box sx={{ mb: 2 }}>
          <TextField
            label="Title"
            name="title"
            value={postData.title}
            onChange={handleInputChange}
            required
            fullWidth
            error={!postData.title.trim()}
          />
        </Box>
        <Box sx={{ mb: 2 }}>
          <TextField
            label="Summary"
            name="summary"
            value={postData.summary}
            onChange={handleInputChange}
            required
            multiline
            rows={4}
            fullWidth
            error={!postData.summary.trim()}
          />
        </Box>
        <Box sx={{ mb: 2 }}>
          <FormControl fullWidth required>
            <InputLabel id="content-type-label">Content Type</InputLabel>
            <Select
              labelId="content-type-label"
              id="content-type"
              name="contentType"
              value={postData.contentType}
              label="Content Type"
              onChange={handleInputChange}
            >
              <MenuItem value="ANNOUNCEMENT">Announcement</MenuItem>
              <MenuItem value="CTA">Call to Action</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {postData.contentType === "CTA" && (
          <Box sx={{ mb: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="cta-type-label">CTA Type</InputLabel>
              <Select
                labelId="cta-type-label"
                id="cta-type"
                name="ctaType"
                value={postData.ctaType || ""}
                label="CTA Type"
                onChange={handleInputChange}
              >
                <MenuItem value="join_now">Join Now</MenuItem>
                <MenuItem value="attend_event">Attend Event</MenuItem>
                <MenuItem value="interested">Interested</MenuItem>
                <MenuItem value="donate_now">Donate Now</MenuItem>
              </Select>
            </FormControl>
          </Box>
        )}

        <CategorySelector 
          categories={postData.categories} 
          onCategoryChange={handleCategoryChange}
        />

        <Box sx={{ mb: 2 }}>
          <TextField
            label="Tags (comma-separated)"
            name="tags"
            value={postData.tags.length > 0 ? postData.tags.join(", ") : ""}
            onChange={(e) => {
              const value = e.target.value;
              setPostData((prev) => ({
                ...prev,
                tags: value.split(",").map((item) => item.trim())
              }));
            }}
            fullWidth
          />
        </Box>

        <Box sx={{ mb: 2 }}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            Content Blocks
          </Typography>
          {postData.contentBlocks.map((block, index) => (
            <ContentBlock
              key={index}
              block={block}
              index={index}
              updateBlock={updateContentBlock}
              removeBlock={removeContentBlock}
              moveBlock={moveContentBlock}
              onFileChange={handleFileChange}
            />
          ))}
          <Button
            variant="outlined"
            onClick={addNewContentBlock}
            sx={{ mt: 2 }}
          >
            Add New Content Block
          </Button>
        </Box>
        <Box sx={{ position: 'relative' }}>
          <Button 
            type="submit" 
            variant="contained" 
            color="primary" 
            fullWidth
            disabled={isSubmitting || !postData.title.trim() || !postData.summary.trim() || !postData.contentType || postData.contentBlocks.length === 0}
          >
            {isSubmitting ? 'Creating Post...' : 'Create Post'}
          </Button>
          {isSubmitting && (
            <CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Box>
      </form>
    </Container>
  );
};

export default CreatePost;