import { api } from './api';

export const pollService = {
  // Get paginated list of polls
  getPolls: async (page = 0, size = 10, status = 'all') => {
    try {
      const response = await api.get('/aluminiapp/v2/admin/polls', {
        params: {
          page,
          size,
          status
        }
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Get polls summary
  getPollsSummary: async () => {
    try {
      const response = await api.get('/aluminiapp/v2/admin/polls/summary');
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Get detailed stats for a specific poll
  getPollDetailedStats: async (pollId) => {
    try {
      const response = await api.get(`/aluminiapp/v2/admin/polls/${pollId}/detailed-stats`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Export poll respondents
  exportRespondents: async (pollId) => {
    try {
      const response = await api.get(`/aluminiapp/v2/admin/polls/${pollId}/respondents`, {
        responseType: 'text', // CSV format
      });
      
      // Create a download link and trigger the download
      const csvData = response.data;
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `poll-${pollId}-respondents.csv`);
      document.body.appendChild(link);
      link.click();
      
      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
      
      return true;
    } catch (error) {
      console.error('Export poll respondents error:', error.response?.data || error.message);
      throw error;
    }
  },

  // Get aggregate statistics
  getAggregateStats: async (timeframe = 'monthly', fromDate, toDate) => {
    try {
      const response = await api.get('/aluminiapp/v2/admin/polls/stats/aggregate', {
        params: {
          timeframe,
          fromDate,
          toDate
        }
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
};

export default pollService;