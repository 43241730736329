import { api } from './api';

const BASE_PATH = '/aluminiapp/v2/schools/udise';
const DEFAULT_UDISE = 'all';

export const ParticipationService = {
  // Interests endpoints
  getInterests: async (udiseCode = DEFAULT_UDISE, filters = {}) => {
    try {
      const response = await api.get(`${BASE_PATH}/${udiseCode}/participation/interests`, {
        params: filters
      });
      return response.data;
    } catch (error) {
      console.error('Get interests error:', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  getInterestById: async (udiseCode = DEFAULT_UDISE, interestId) => {
    try {
      const response = await api.get(`${BASE_PATH}/${udiseCode}/participation/interests/${interestId}`);
      return response.data;
    } catch (error) {
      console.error('Get interest by id error:', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  // Activities endpoints
  getActivities: async (udiseCode = DEFAULT_UDISE, filters = {}) => {
    try {
      const response = await api.get(`${BASE_PATH}/${udiseCode}/participation/activities`, {
        params: filters
      });
      return response.data;
    } catch (error) {
      console.error('Get activities error:', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  getActivityById: async (udiseCode = DEFAULT_UDISE, activityId) => {
    try {
      const response = await api.get(`${BASE_PATH}/${udiseCode}/participation/activities/${activityId}`);
      return response.data;
    } catch (error) {
      console.error('Get activity by id error:', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  // Stats and analytics
  getParticipationStats: async (udiseCode = DEFAULT_UDISE, filters = {}) => {
    try {
      const response = await api.get(`${BASE_PATH}/${udiseCode}/participation/stats`, {
        params: filters
      });
      return response.data;
    } catch (error) {
      console.error('Get participation stats error:', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  // Export functions
  exportInterests: async (udiseCode = DEFAULT_UDISE, participationType = '') => {
    try {
      const params = participationType ? { participationType } : {};
      const response = await api.get(`${BASE_PATH}/${udiseCode}/participation/interests/export`, {
        params,
        responseType: 'text', // CSV format
      });
      
      // Create a download link and trigger the download
      const csvData = response.data;
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `interests-${udiseCode}-${participationType || 'all'}.csv`);
      document.body.appendChild(link);
      link.click();
      
      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
      
      return true;
    } catch (error) {
      console.error('Export interests error:', error.response?.data || error.message);
      throw error;
    }
  },

  exportActivities: async (udiseCode = DEFAULT_UDISE, participationType = '') => {
    try {
      const params = participationType ? { participationType } : {};
      const response = await api.get(`${BASE_PATH}/${udiseCode}/participation/activities/export`, {
        params,
        responseType: 'text', // CSV format
      });
      
      // Create a download link and trigger the download
      const csvData = response.data;
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `activities-${udiseCode}-${participationType || 'all'}.csv`);
      document.body.appendChild(link);
      link.click();
      
      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
      
      return true;
    } catch (error) {
      console.error('Export activities error:', error.response?.data || error.message);
      throw error;
    }
  },

  exportContributors: async (udiseCode = DEFAULT_UDISE, participationType = '') => {
    try {
      const params = participationType ? { participationType } : {};
      const response = await api.get(`${BASE_PATH}/${udiseCode}/participation/contributors/export`, {
        params,
        responseType: 'text', // CSV format
      });
      
      // Create a download link and trigger the download
      const csvData = response.data;
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `contributors-${udiseCode}-${participationType || 'all'}.csv`);
      document.body.appendChild(link);
      link.click();
      
      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
      
      return true;
    } catch (error) {
      console.error('Export contributors error:', error.response?.data || error.message);
      throw error;
    }
  }
};

export const PARTICIPATION_TYPES = {
  VOLUNTEER: 'VOLUNTEER',
  SKILL_CONTRIBUTION: 'SKILL_CONTRIBUTION',
  DONATION: 'DONATION'
};

export const AVAILABILITY_TYPES = {
  WEEKDAYS: 'WEEKDAYS',
  WEEKENDS: 'WEEKENDS'
};

export const TIMING_TYPES = {
  MORNING: 'MORNING',
  AFTERNOON: 'AFTERNOON',
  EVENING: 'EVENING'
};

export const FREQUENCY_TYPES = {
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
  QUARTERLY: 'QUARTERLY',
  YEARLY: 'YEARLY'
};