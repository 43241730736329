import {api} from './api';

const BASE_URL = '/aluminiapp/v2/post-reports';

export const getReportsForPost = async (postId, page = 0, size = 20) => {
  return api.get(`${BASE_URL}/post/${postId}`, { params: { page, size } });
};

export const getHighlyReportedPosts = async (page = 0, size = 20, minReportCount = 5) => {
  return api.get(`${BASE_URL}/analytics/highly-reported`, { 
    params: { page, size, minReportCount } 
  });
};