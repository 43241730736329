import React, { useState } from 'react';
import { TextField, Button, Box, Typography, CircularProgress } from '@mui/material';
import { sendOTP } from '../../services/authService';

const RequestOTP = ({ onSuccess, phoneNumber, setPhoneNumber }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      await sendOTP(phoneNumber);
      onSuccess();
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to send OTP. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
      <Typography variant="h6" gutterBottom>
        Enter your phone number to reset password
      </Typography>
      
      <TextField
        margin="normal"
        required
        fullWidth
        id="phoneNumber"
        label="Phone Number"
        name="phoneNumber"
        autoComplete="tel"
        autoFocus
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
        error={!!error}
        helperText={error}
        disabled={loading}
        inputProps={{
          pattern: "[0-9]*",
          maxLength: 10,
        }}
      />

      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        disabled={loading || !phoneNumber || phoneNumber.length !== 10}
      >
        {loading ? <CircularProgress size={24} /> : 'Send OTP'}
      </Button>
    </Box>
  );
};

export default RequestOTP;