import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Box,
  Grid,
  Button,
  Pagination,
  CircularProgress,
  Breadcrumbs,
  Link,
  Divider,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import FolderIcon from '@mui/icons-material/Folder';
import { galleryService } from '../../services/galleryService';
import GalleryFolder from '../../components/chat/GalleryFolder';
import FolderDialog from '../../components/chat/FolderDialog';

const GalleryDetails = () => {
  const { galleryId } = useParams();
  const navigate = useNavigate();
  
  const [gallery, setGallery] = useState(null);
  const [folders, setFolders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [folderDialogOpen, setFolderDialogOpen] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [folderToDelete, setFolderToDelete] = useState(null);
  const [refreshKey, setRefreshKey] = useState(0);

  const pageSize = 12;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError('');
      try {
        // Fetch gallery details
        const galleryResponse = await galleryService.getGalleryById(galleryId);
        if (galleryResponse && galleryResponse.data) {
          setGallery(galleryResponse.data);
          
          // Fetch folders for this gallery
          const foldersResponse = await galleryService.getFoldersForGallery(
            galleryId, 
            page - 1, 
            pageSize
          );
          
          console.log('Folders response:', foldersResponse);
          if (foldersResponse && foldersResponse.data) {
            setFolders(foldersResponse.data.content || []);
            setTotalPages(foldersResponse.data.totalPages || 1);
          } else {
            console.error('Unexpected folders response format:', foldersResponse);
            setError('Unexpected response format when loading folders. Please try again.');
          }
        } else {
          console.error('Unexpected gallery response format:', galleryResponse);
          setError('Unexpected response format when loading gallery. Please try again.');
        }
      } catch (err) {
        console.error('Error fetching data:', err);
        if (err.response) {
          console.error('Error response:', err.response.data);
          setError(`Failed to load gallery data: ${err.response.data.message || 'Server error'}`);
        } else if (err.request) {
          setError('Network error. Please check your connection.');
        } else {
          setError('Failed to load gallery data. Please try again.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [galleryId, page, refreshKey]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleAddFolder = () => {
    setSelectedFolder(null);
    setFolderDialogOpen(true);
  };

  const handleEditFolder = (folder) => {
    setSelectedFolder(folder);
    setFolderDialogOpen(true);
  };

  const handleDeletePrompt = (folder) => {
    setFolderToDelete(folder);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!folderToDelete) return;
    
    try {
      await galleryService.deleteFolder(folderToDelete.id);
      setDeleteDialogOpen(false);
      setFolderToDelete(null);
      // Refresh the folder list
      setRefreshKey(prev => prev + 1);
    } catch (err) {
      console.error('Error deleting folder:', err);
      setError('Failed to delete folder. Please try again.');
    }
  };

  const handleSaveFolder = async (formData, coverImage, folderId) => {
    try {
      if (folderId) {
        // Update existing folder
        const response = await galleryService.updateFolder(folderId, formData, coverImage);
        console.log('Folder update response:', response);
      } else {
        // Create new folder
        const response = await galleryService.createFolder(formData, coverImage);
        console.log('Folder creation response:', response);
      }
      // Refresh folder list
      setRefreshKey(prev => prev + 1);
      return true;
    } catch (err) {
      console.error('Error saving folder:', err);
      throw err;
    }
  };

  if (loading && !gallery) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      {/* Breadcrumb Navigation */}
      <Box mb={3}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
          <Link 
            color="inherit" 
            component="button"
            onClick={() => navigate('/chat-admin')}
            sx={{ textDecoration: 'none' }}
          >
            Chat Groups
          </Link>
          <Link 
            color="inherit" 
            component="button"
            onClick={() => navigate(`/chat-gallery/groups/${gallery?.chatGroupId}`)}
            sx={{ textDecoration: 'none' }}
          >
            Galleries
          </Link>
          <Typography color="text.primary">
            {gallery?.name || 'Gallery Details'}
          </Typography>
        </Breadcrumbs>
      </Box>
      
      {/* Header Section */}
      <Box 
        display="flex" 
        justifyContent="space-between" 
        alignItems="center"
        mb={3}
      >
        <Box>
          <Typography variant="h4" gutterBottom>
            {gallery?.name || 'Gallery Details'}
          </Typography>
          {gallery?.description && (
            <Typography variant="body1" color="text.secondary">
              {gallery.description}
            </Typography>
          )}
        </Box>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddFolder}
        >
          Add Folder
        </Button>
      </Box>
      
      <Divider sx={{ mb: 3 }} />
      
      {/* Error Message */}
      {error && (
        <Alert 
          severity="error" 
          sx={{ mb: 3 }}
          action={
            <Button 
              color="inherit" 
              size="small" 
              startIcon={<RefreshIcon />}
              onClick={() => setRefreshKey(prev => prev + 1)}
            >
              Retry
            </Button>
          }
        >
          {error}
        </Alert>
      )}
      
      {/* Folders Grid */}
      {folders.length > 0 ? (
        <Grid container spacing={3}>
          {folders.map((folder) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={folder.id}>
              <GalleryFolder 
                folder={folder} 
                onEdit={handleEditFolder}
                onDelete={handleDeletePrompt}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box 
          sx={{ 
            minHeight: '40vh', 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            justifyContent: 'center',
            backgroundColor: 'rgba(94, 53, 177, 0.05)',
            borderRadius: 2,
            p: 3
          }}
        >
          <FolderIcon sx={{ fontSize: 80, color: '#5E35B1', opacity: 0.5, mb: 2 }} />
          <Typography variant="h6" color="text.secondary" gutterBottom>
            No folders found
          </Typography>
          <Typography variant="body2" color="text.secondary" align="center" mb={3}>
            Create your first folder in this gallery by clicking the "Add Folder" button.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleAddFolder}
          >
            Add Folder
          </Button>
        </Box>
      )}
      
      {/* Pagination */}
      {totalPages > 1 && (
        <Box display="flex" justifyContent="center" mt={4}>
          <Pagination 
            count={totalPages} 
            page={page}
            onChange={handlePageChange}
            color="primary"
          />
        </Box>
      )}
      
      {/* Folder Dialog */}
      <FolderDialog
        open={folderDialogOpen}
        onClose={() => setFolderDialogOpen(false)}
        folder={selectedFolder}
        galleryId={galleryId}
        onSave={handleSaveFolder}
        title={selectedFolder ? 'Edit Folder' : 'Create New Folder'}
      />
      
      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the folder "{folderToDelete?.name}"? 
            This will also delete all media files within this folder.
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default GalleryDetails;