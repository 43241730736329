import { api } from './api';

export const createSMCEvent = async (eventData) => {
  try {
    const response = await api.post('/aluminiapp/v2/smcevents', {
      eventName: eventData.title,
      description: eventData.description,
      eventDate: eventData.eventDate,
      eventTime: eventData.eventTime.substring(0, 5),
    });
    return response.data;
  } catch (error) {
    console.error('Create SMC event error:', error.response?.data || error.message);
    throw error;
  }
};

export const updateEvent = async (eventId, eventData) => {
  try {
    const response = await api.put(`/aluminiapp/v2/smcevents/${eventId}`, {
      eventName: eventData.title,
      description: eventData.description,
      eventDate: eventData.eventDate,
      eventTime: eventData.eventTime.substring(0, 5),
    });
    return response.data;
  } catch (error) {
    console.error('Update event error:', error.response?.data || error.message);
    throw error;
  }
};

export const getEventById = async (eventId) => {
  try {
    const response = await api.get(`/aluminiapp/v2/smcevents/${eventId}`);
    return response.data;
  } catch (error) {
    console.error('Get event error:', error.response?.data || error.message);
    throw error;
  }
};

export const getEventsByMonth = async (year, month, page = 0, size = 10) => {
  try {
    const response = await api.get(`/aluminiapp/v2/smcevents/${year}/${month}`, {
      params: { page, size }
    });
    return response.data;
  } catch (error) {
    console.error('Get events error:', error.response?.data || error.message);
    throw error;
  }
};

export const deleteEvent = async (eventId) => {
  try {
    await api.delete(`/aluminiapp/v2/smcevents/${eventId}`);
  } catch (error) {
    console.error('Delete event error:', error.response?.data || error.message);
    throw error;
  }
};

export const getEventRSVPStats = async (eventId) => {
  try {
    const response = await api.get(`/aluminiapp/v2/smcevents/${eventId}/rsvp/stats`);
    return response.data;
  } catch (error) {
    console.error('Get RSVP stats error:', error.response?.data || error.message);
    throw error;
  }
};

export const exportEventMembers = async (eventId) => {
  try {
    const response = await api.get(`/aluminiapp/v2/smcevents/${eventId}/members/export`, {
      responseType: 'text', // Change to text since we're getting CSV
    });
    
    // Create a download link and trigger the download
    const csvData = response.data;
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `smc-event-${eventId}-members.csv`);
    document.body.appendChild(link);
    link.click();
    
    // Clean up
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
    
    return true;
  } catch (error) {
    console.error('Export event members error:', error.response?.data || error.message);
    throw error;
  }
};