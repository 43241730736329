import React from 'react';
import {
  Box,
  TextField,
  InputAdornment,
  IconButton,
  CircularProgress,
} from '@mui/material';
import { Search, Clear } from '@mui/icons-material';

const UserSearch = ({
  searchTerm,
  onSearchChange,
  onClearSearch,
  loading = false,
  placeholder = "Search users by name, email, or phone (enter 10 digits for phone search)",
}) => {
  return (
    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', width: '100%', maxWidth: '600px' }}>
      <TextField
        fullWidth
        placeholder={placeholder}
        value={searchTerm}
        onChange={onSearchChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          endAdornment: searchTerm && (
            <InputAdornment position="end">
              {loading ? (
                <CircularProgress size={20} />
              ) : (
                <IconButton onClick={onClearSearch}>
                  <Clear />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default UserSearch;