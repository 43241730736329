// VideoBlock.js
import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Button, 
  TextField, 
  Typography, 
  CardMedia, 
  Grid, 
  Paper,
  Divider,
  Card,
  CardContent,
  IconButton,
  Tooltip,
  Zoom
} from '@mui/material';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import ImageIcon from '@mui/icons-material/Image';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const VideoBlock = ({ block, updateBlock, onFileChange, postId }) => {
  const [thumbnailPreview, setThumbnailPreview] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);
  const [videoSelected, setVideoSelected] = useState(false);
  const [videoName, setVideoName] = useState('');

  // Check if video was previously selected
  useEffect(() => {
    // If there's a URL set or if we detect a file is expected to be uploaded
    if (block.videoContent?.url || block.videoContent?.fileName) {
      setVideoSelected(true);
      setVideoName(block.videoContent?.fileName || 'Video selected');
    }
  }, [block.videoContent]);

  useEffect(() => {
    // Initialize thumbnailPreview from existing URL if available
    if (block.videoContent?.thumbnailUrl) {
      setThumbnailPreview(block.videoContent.thumbnailUrl);
    }
    
    // Cleanup function to revoke created object URLs
    return () => {
      if (videoPreview) {
        URL.revokeObjectURL(videoPreview);
      }
    };
  }, [block.videoContent?.thumbnailUrl]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update the block with new value
    updateBlock({
      ...block,
      videoContent: {
        ...block.videoContent,
        [name]: value,
      }
    });
  };

  const handleVideoFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const safePostId = postId || Date.now().toString();
      
      // Generate filename following the recommended convention
      const videoFileName = `${safePostId}_${block.order}_${file.name}`;
      
      // Create a new File object with the custom filename
      const videoFile = new File([file], videoFileName, {
        type: file.type,
        lastModified: file.lastModified
      });
      Object.defineProperty(videoFile, 'fileType', { value: 'video', writable: false, configurable: true });

      console.log('VideoBlock sending file:', videoFile);
      // Debug logging for video file
      console.log('VideoBlock - Video file prepared:', {
        fileName: videoFile.name,
        fileType: videoFile.fileType,
        blockOrder: block.order
      });
      onFileChange([videoFile]);
      
      // Set video as selected and store the filename
      setVideoSelected(true);
      setVideoName(file.name);
      
      // Create a preview URL for the video
      const videoURL = URL.createObjectURL(file);
      setVideoPreview(videoURL);
      
      updateBlock({
        ...block,
        videoContent: {
          ...block.videoContent,
          url: '',
          duration: '',
          fileName: file.name, // Store the original filename for display
          caption: block.videoContent?.caption || '',
          thumbnailUrl: block.videoContent?.thumbnailUrl || '',
        }
      });
    }
  };
  
  const handleThumbnailFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const safePostId = postId || Date.now().toString();
  
      // Generate thumbnail filename following the recommended convention
      const thumbnailFileName = `${safePostId}_${block.order}_thumbnail_${file.name}`;
      
      // Create a new File object with the custom filename
      const thumbnailFile = new File([file], thumbnailFileName, {
        type: file.type,
        lastModified: file.lastModified
      });
      thumbnailFile.fileType = 'thumbnail';

      // Create preview of the uploaded thumbnail
      const reader = new FileReader();
      reader.onloadend = () => {
        setThumbnailPreview(reader.result);
      };
      reader.readAsDataURL(file);
  
      console.log('VideoBlock sending thumbnail:', thumbnailFile);
      // Debug logging for thumbnail file
      console.log('VideoBlock - Thumbnail file prepared:', {
        fileName: thumbnailFile.name,
        fileType: thumbnailFile.fileType,
        blockOrder: block.order
      });
      onFileChange([thumbnailFile]);
  
      // Update block with thumbnail information
      updateBlock({
        ...block,
        videoContent: {
          ...block.videoContent,
          thumbnailUrl: '', // Clear URL as we now have a file
        }
      });
    }
  };

  const hasThumbnail = !!(block.videoContent?.thumbnailUrl || thumbnailPreview);

  return (
    <Card 
      elevation={0} 
      sx={{ 
        mt: 2, 
        border: '1px solid #e0e0e0', 
        borderRadius: 2,
        overflow: 'visible'
      }}
    >
      <CardContent sx={{ p: 3 }}>
        <Typography variant="h6" gutterBottom sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          color: '#5E35B1',
          mb: 2
        }}>
          <VideoFileIcon sx={{ mr: 1 }} />
          Video Content
          <Tooltip 
            title="Upload a video and thumbnail to create engaging content. The thumbnail will be shown before the video plays." 
            placement="top"
            TransitionComponent={Zoom}
          >
            <IconButton size="small" sx={{ ml: 1 }}>
              <InfoOutlinedIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Typography>
        
        <Grid container spacing={3}>
          {/* Left column: Video upload and information */}
          <Grid item xs={12} md={6}>
            <Box sx={{ 
              bgcolor: 'background.paper', 
              borderRadius: 2, 
              p: 2,
              border: '1px dashed #c5c5c5',
              height: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}>
              <Typography 
                variant="subtitle1" 
                sx={{ 
                  fontWeight: 600, 
                  display: 'flex', 
                  alignItems: 'center',
                  color: '#333'
                }}
              >
                <VideoFileIcon sx={{ mr: 1, color: '#5E35B1' }} /> 
                Video File
              </Typography>
                
              <Button 
                variant="contained" 
                component="label" 
                fullWidth 
                sx={{ 
                  mt: 2,
                  mb: 2, 
                  py: 1.2,
                  backgroundColor: '#5E35B1',
                  '&:hover': {
                    backgroundColor: '#4527A0'
                  }
                }}
                startIcon={<VideoFileIcon />}
              >
                {videoSelected ? "Change Video" : "Upload Video"}
                <input 
                  type="file" 
                  accept="video/*" 
                  hidden 
                  onChange={handleVideoFileChange} 
                />
              </Button>
              
              {videoSelected ? (
                <Box 
                  sx={{ 
                    bgcolor: 'rgba(94, 53, 177, 0.1)',
                    borderRadius: 2,
                    border: '1px solid rgba(94, 53, 177, 0.3)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    flexGrow: 1,
                    overflow: 'hidden'
                  }}
                >
                  {videoPreview && (
                    <Box sx={{ width: '100%', pt: 2, px: 2 }}>
                      <video 
                        controls 
                        width="100%" 
                        height="auto" 
                        style={{ 
                          borderRadius: '8px',
                          maxHeight: '140px',
                          backgroundColor: '#000'
                        }}
                      >
                        <source src={videoPreview} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </Box>
                  )}
                  <Box sx={{ 
                    p: 2, 
                    display: 'flex', 
                    alignItems: 'center', 
                    width: '100%'
                  }}>
                    <CheckCircleIcon sx={{ mr: 1.5, color: '#5E35B1', flexShrink: 0 }} />
                    <Box sx={{ overflow: 'hidden', flexGrow: 1 }}>
                      <Tooltip title={videoName} placement="top">
                        <Typography 
                          variant="body2" 
                          noWrap 
                          sx={{ 
                            color: '#5E35B1', 
                            fontWeight: 500
                          }}
                        >
                          {videoName}
                        </Typography>
                      </Tooltip>
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box 
                  sx={{ 
                    p: 2, 
                    bgcolor: '#f5f5f5',
                    borderRadius: 2,
                    border: '1px solid #e0e0e0',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexGrow: 1
                  }}
                >
                  <Typography variant="body2" sx={{ color: '#666', fontStyle: 'italic' }}>
                    No video selected
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>
          
          {/* Right column: Thumbnail upload and preview */}
          <Grid item xs={12} md={6}>
            <Box sx={{ 
              bgcolor: 'background.paper', 
              borderRadius: 2, 
              p: 2,
              border: '1px dashed #c5c5c5',
              height: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}>
              <Typography 
                variant="subtitle1" 
                sx={{ 
                  fontWeight: 600, 
                  display: 'flex', 
                  alignItems: 'center',
                  color: '#333'
                }}
              >
                <ImageIcon sx={{ mr: 1, color: '#5E35B1' }} /> 
                Thumbnail Image
              </Typography>
              
              <Button 
                variant="contained" 
                component="label" 
                fullWidth 
                sx={{ 
                  mt: 2,
                  mb: 2,
                  py: 1.2,
                  backgroundColor: '#5E35B1',
                  '&:hover': {
                    backgroundColor: '#4527A0'
                  }
                }}
                startIcon={<ImageIcon />}
              >
                {hasThumbnail ? "Change Thumbnail" : "Upload Thumbnail"}
                <input 
                  type="file" 
                  accept="image/*" 
                  hidden 
                  onChange={handleThumbnailFileChange} 
                />
              </Button>
              
              {hasThumbnail ? (
                <Box 
                  sx={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexGrow: 1,
                    position: 'relative'
                  }}
                >
                  <CardMedia
                    component="img"
                    image={thumbnailPreview || block.videoContent.thumbnailUrl}
                    alt="Thumbnail Preview"
                    sx={{ 
                      height: 'auto',
                      width: '100%',
                      maxHeight: 140, 
                      objectFit: 'contain', 
                      borderRadius: 1
                    }}
                  />
                  <Typography 
                    variant="caption" 
                    sx={{ 
                      mt: 1,
                      color: '#5E35B1',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <CheckCircleIcon sx={{ mr: 0.5, fontSize: '1rem' }} />
                    Thumbnail selected
                  </Typography>
                </Box>
              ) : (
                <Box 
                  sx={{ 
                    p: 2, 
                    bgcolor: '#f5f5f5',
                    borderRadius: 2,
                    border: '1px solid #e0e0e0',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexGrow: 1
                  }}
                >
                  <Typography variant="body2" sx={{ color: '#666', fontStyle: 'italic' }}>
                    No thumbnail selected
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
        
        <Divider sx={{ my: 3 }} />
        
        {/* Caption field */}
        <TextField
          name="caption"
          label="Video Caption"
          value={block.videoContent?.caption || ''}
          onChange={handleChange}
          placeholder="Add a caption for this video"
          fullWidth
          multiline
          rows={2}
          variant="outlined"
          sx={{ 
            mt: 1,
            '& .MuiOutlinedInput-root': {
              borderRadius: 2,
              '&.Mui-focused fieldset': {
                borderColor: '#5E35B1',
              },
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: '#5E35B1',
            }
          }}
          InputProps={{
            sx: { px: 2 }
          }}
        />
        
        <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mt: 1 }}>
          Add a descriptive caption to help users understand what this video contains.
        </Typography>
      </CardContent>
    </Card>
  );
};

export default VideoBlock;